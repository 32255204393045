import {
  LocationOfCourse,
  StudentNeedEducationalStage,
} from '@les-sherpas/sherpas-toolbox';
import { useRouter } from 'next/router';
import { UrlObject } from 'url';

import { Screen } from '@/components/OnBoardingStudent/common/OnboardingStudent.type';

const filteredSearchParams = (searchParams: object) =>
  Object.fromEntries(
    Object.entries(searchParams).filter(
      ([_, value]) => value !== undefined && value !== null
    )
  );

const urlWithFilteredSearchParams = (url: string, searchParams?: object) => {
  if (searchParams) {
    const filteredParams = filteredSearchParams(searchParams);
    const urlSearchParams = new URLSearchParams(filteredParams).toString();
    const completeUrl = `${url}${urlSearchParams ? `?${urlSearchParams}` : ''}`;

    return completeUrl;
  }
  return url;
};

export const urls = {
  home: (
    searchParams?: { scrollTo: 'steps' | 'fights' } | { signup: 'true' }
  ) => {
    const baseUrl = '/';
    const url = urlWithFilteredSearchParams(baseUrl, searchParams);
    return url as `${typeof baseUrl}` | `${typeof baseUrl}?${string}`;
  },
  homeMaman: () => '/mamanjusquauboutdesongles' as const,
  homeOxford: () => '/oxford' as const,
  homeVuibert: () => '/vuibert' as const,
  homeHachette: () => '/hachette' as const,
  search: (searchParams?: {
    address?: string;
    educationalStages?: StudentNeedEducationalStage;
    courseLocation?: LocationOfCourse;
    subject?: string;
  }) => {
    const baseUrl = '/professeurs';
    const url = urlWithFilteredSearchParams(baseUrl, searchParams);
    return url as `${typeof baseUrl}` | `${typeof baseUrl}?${string}`;
  },
  teacherAnnounce: ({ seoId }: { seoId: string }) =>
    `/t/${encodeURIComponent(seoId)}` as const,
  givePrivateCourse: () => '/donner-cours-particuliers' as const,
  helpCenter: () => '/centre-aide' as const,
  legalNotice: () => '/mentions-legales' as const,
  privacyPolicy: () => '/charte-confidentialite' as const,
  privateLessonsFrance: () => '/cours-particuliers-france' as const,
  privateLessonsSubjects: () => '/cours-particuliers-matieres' as const,
  blog: () => 'https://sherpas.com/blog/' as const,
  blogAbout: () => 'https://sherpas.com/blog/parents/a-propos/' as const,
  blogKitBeginner: () =>
    'https://sherpas.com/blog/tag/kit-du-sherpa-debutant/' as const,
  blogKitIntermediate: () =>
    'https://sherpas.com/blog/tag/kit-du-sherpa-sur/' as const,
  blogKitExpert: () =>
    'https://sherpas.com/blog/tag/kit-du-sherpa-expert/' as const,
  resetPassword: () => '/resetmdp' as const,
  announce: ({
    seoId,
    searchParams,
  }: {
    seoId: string;
    searchParams?: { subjectName: string };
  }) => {
    const baseUrl = `/t/${encodeURIComponent(seoId)}` as const;
    const url = urlWithFilteredSearchParams(baseUrl, searchParams);
    return url as `${typeof baseUrl}` | `${typeof baseUrl}?${string}`;
  },
  login: () => '/login' as const,
  signUp: () => '/signup' as const,
  sherpaSignUp: (searchParams?: { step?: number }) => {
    const baseUrl = '/sherpa/signup';
    const url = urlWithFilteredSearchParams(baseUrl, searchParams);
    return url as `${typeof baseUrl}/${string}`;
  },
  dashboardSummary: (searchParams?: { redirect?: boolean }) => {
    const baseUrl = '/tableau-de-bord';
    const url = urlWithFilteredSearchParams(baseUrl, searchParams);
    return url as `${typeof baseUrl}/${string}`;
  },
  dashboardIncomes: () => '/tableau-de-bord/revenus' as const,
  dashboardFollowUp: () => '/tableau-de-bord/suivi' as const,
  dashboardFollowUpForChatRoom: ({ chatRoomId }: { chatRoomId: number }) =>
    `/tableau-de-bord/suivi/${chatRoomId}}` as const,
  dashboardFavorites: () => '/tableau-de-bord/favoris' as const,
  calendly: () =>
    'https://calendly.com/formation-sherpas/formation-prof-sherpa-30-min' as const,
  chatRooms: () => '/messages' as const,
  chatRoom: (searchParams: { chatRoomId: number }) => {
    const baseUrl = '/messages';
    const url = urlWithFilteredSearchParams(baseUrl, searchParams);
    return url as `${typeof baseUrl}?${string}`;
  },
  chatRoomCourse: (searchParams: { chatRoomId: number; courseId: number }) => {
    const baseUrl = '/messages';
    const url = urlWithFilteredSearchParams(baseUrl, searchParams);
    return url as `${typeof baseUrl}?${string}`;
  },
  payment: (searchParams: { id: number; subjectName: string }) => {
    const baseUrl = '/paiement';
    const url = urlWithFilteredSearchParams(baseUrl, searchParams);
    return url as `${typeof baseUrl}?${string}`;
  },
  resources: () => '/ressources' as const,
  teacherAnnounces: () => '/annonce' as const,
  settingsHome: () => '/parametre' as const,
  settingsBillingAndPayment: () => '/parametre/facture-et-paiement' as const,
  settingsAvailabilities: () => 'parametre/availabilities' as const,
  settingsCompany: () => 'parametre/entreprise' as const,
  drawerSignUp: (searchParams?: { step: Screen }) => {
    const baseUrl = '/inscription';
    const url = urlWithFilteredSearchParams(baseUrl, searchParams);
    return url as `${typeof baseUrl}` | `${typeof baseUrl}?${string}`;
  },
  drawerSignUpTeacherFromGafam: (searchParams?: {
    onBoard?: boolean;
    step?: number;
  }) => {
    const baseUrl = '/sherpa/register';
    const url = urlWithFilteredSearchParams(baseUrl, searchParams);
    return url as `${typeof baseUrl}?${string}`;
  },
  drawerSignUpTeacherFromEmail: () => '/sherpa/signup' as const,
  drawerTeacherCreateAnnounce: (searchParams: { id: number }) => {
    const baseUrl = '/sherpa/create-announce';
    const url = urlWithFilteredSearchParams(baseUrl, searchParams);
    return url as `${typeof baseUrl}?${string}`;
  },
  drawerCreateDmr: () => '/paiement' as const,
  drawerStudentNeed: (searchParams: {
    originButton: 'search' | 'signup' | 'find-your-sherpa';
  }) => {
    const baseUrl = '/student-need';
    const url = urlWithFilteredSearchParams(baseUrl, searchParams);
    return url as `${typeof baseUrl}/${string}`;
  },
  drawerSponsoring: () => '/sponsoring' as const,
  drawerRefuseTrialCourse: ({ chatRoomId }: { chatRoomId: number }) =>
    `/refus-cours-essai/${chatRoomId}` as const,
  sreFunnel: (searchParams: { chatRoomId: number }) => {
    const baseUrl = '/funnel-suivi-regulier';
    const url = urlWithFilteredSearchParams(baseUrl, searchParams);
    return url as `${typeof baseUrl}?${string}`;
  },
  zendeskPrice: () =>
    'https://les-sherpas.zendesk.com/hc/fr/articles/360008427634-Quels-sont-les-tarifs-de-vos-professeurs-' as const,
  zendeskQuestions: () => 'https://les-sherpas.zendesk.com/hc/fr' as const,
  cancellationFunnel: ({ subscriptionId }: { subscriptionId: number }) =>
    `/resiliation-abonnement/${subscriptionId}` as const,
  gettingStarted: () => `/bien-debuter` as const,
  novaAgreementTutorial: () =>
    `https://www.youtube.com/watch?v=MbrwvpTycrY` as const,
};

const mobileAppLinkBase = 'lessherpas://';
export const mobileUrls = {
  confirmAccountDeleted: () =>
    `${mobileAppLinkBase}confirmAccountDeleted` as const,
  courses: () => `${mobileAppLinkBase}courses` as const,
};

export type UrlConfigReturnTypes = {
  [K in keyof typeof urls]: ReturnType<(typeof urls)[K]>;
}[keyof typeof urls];

export type MobileUrlConfigReturnTypes = {
  [K in keyof typeof mobileUrls]: ReturnType<(typeof mobileUrls)[K]>;
}[keyof typeof mobileUrls];

const useNavigation = () => {
  const { back, push, replace, asPath, query } = useRouter();

  const pushTo = (
    url: UrlConfigReturnTypes | MobileUrlConfigReturnTypes,
    as?: Parameters<typeof push>[1],
    options?: Parameters<typeof push>[2]
  ) => {
    push(url, as, options);
  };

  const pushToUnsafe = (
    url: UrlObject | string,
    as?: Parameters<typeof push>[1],
    options?: Parameters<typeof push>[2]
  ) => {
    push(url, as, options);
  };

  const replaceTo = (
    url: UrlConfigReturnTypes,
    as?: Parameters<typeof push>[1],
    options?: Parameters<typeof push>[2]
  ) => {
    replace(url, as, options);
  };

  const replaceToUnsafe = (
    url: UrlObject | string,
    as?: Parameters<typeof push>[1],
    options?: Parameters<typeof push>[2]
  ) => {
    replace(url, as, options);
  };

  return {
    back,
    pushTo,
    pushToUnsafe,
    replaceTo,
    replaceToUnsafe,
    urls,
    mobileUrls,
    asPath,
    query,
  };
};

export default useNavigation;

// Utility types to make sure that UrlConfigReturnTypes is not broken and does not
// simply fallback to be `string`.
type DoesExtends<TYPE, REFERENCE> = REFERENCE extends TYPE ? true : false;
type ExpectFalse<T extends false> = T;
// If you have a type error below, you need to check the return types of the methods
// exposed in `urls`. One of them returns directly a `string` while it should not.
// NB: This needs to be exported to avoid a lint error.
export type UrlConfigReturnTypesCheck = ExpectFalse<
  DoesExtends<UrlConfigReturnTypes, string>
>;
export type MobileUrlConfigReturnTypesCheck = ExpectFalse<
  DoesExtends<MobileUrlConfigReturnTypes, string>
>;
