import { FC, HTMLAttributes, PropsWithChildren } from 'react';

import useStyles from './styles';

export type AdornmentType = {
  variant: 'start' | 'end';
} & HTMLAttributes<HTMLDivElement>;

const Adornment: FC<PropsWithChildren<AdornmentType>> = ({
  children,
  variant,
  className,
}) => {
  const isPositionStart = variant === 'start';
  const { classes, cx } = useStyles({ isPositionStart });

  return <div className={cx(classes.adornment, className)}>{children}</div>;
};
export default Adornment;
