import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import noCssLinkTag from '@/styles/noCssTag';

export default makeStyles()(() => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    marginRight: rem(40),
    width: '100%',
    ...noCssLinkTag,
    /** These css are only for no-javascript (seo, crawler) crawlers */
    '@media (max-width: 900px)': {
      marginRight: rem(20),
      minWidth: rem(40),
    },
  },
}));
