import { formValidators } from '@les-sherpas/sherpas-toolbox/dist';

import commonMessages from '@/utils/common.messages';

const fieldsConfig = (formatMessage) => [
  {
    name: 'username',
    defaultValue: '',
    validators: [
      {
        validator: formValidators.validateEmail,
        message: formatMessage(commonMessages.emailValidatorError),
      },
    ],
  },
  {
    name: 'password',
    defaultValue: '',
    validators: [
      {
        validator: formValidators.isNotEmpty,
        message: formatMessage(commonMessages.passwordValidatorError),
      },
    ],
  },
];

export default fieldsConfig;
