import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import NextLinkWithNoCss from '@/components/NextLinkWithNoCss/NextLinkWithNoCss';
import Button from '@/designSystem/Button/Button';
import { useIsMobile } from '@/utils/useResponsive';

import messages from './messages';

import useStyles from './styles';

type Props = {
  isRow?: boolean;
};

const ButtonFooter: FC<
  PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>
> = ({ className, isRow = false }) => {
  const { formatMessage } = useIntl();
  const { classes, cx } = useStyles({ isRow });
  const isMobile = useIsMobile();

  return (
    <div className={cx(classes.buttonFooter, className)}>
      <Typography
        variant="bodyLgBold"
        component="span"
        className={classes.label}
      >
        <FormattedMessage {...messages.needHelp} />
      </Typography>
      <NextLinkWithNoCss
        href="/centre-aide"
        onClick={() => {
          window.dataLayer.push({
            event: 'Contact_us',
          });
        }}
      >
        <Button
          variant="primary"
          size="medium"
          isFullWidth={isMobile}
          label={formatMessage(messages.contact)}
        />
      </NextLinkWithNoCss>
    </div>
  );
};

export default ButtonFooter;
