import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints }) => ({
  footer: {
    backgroundColor: palette.neutral.light,
    padding: `${rem(20)} ${rem(16)} ${rem(12)}`,
    [breakpoints.up('md')]: {
      padding: `${rem(20)} ${rem(20)} ${rem(12)}`,
    },
    [breakpoints.up('lg')]: {
      padding: `${rem(60)} ${rem(80)} ${rem(12)}`,
    },
  },
  bottomFooter: {
    marginTop: rem(40),
  },
  sections: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    gap: rem(28),
    [breakpoints.up('md')]: {
      flexFlow: 'row',
      alignItems: 'flex-start',
    },
  },
  mobileButtonFooter: {
    width: '100%',
    [breakpoints.up('lg')]: {
      width: 'inherit',
    },
  },
  logo: {
    flex: 1,
  },
  tabletTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: rem(28),
  },
}));
