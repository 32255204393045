import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'layout.card.error.title',
    defaultMessage: 'Moyen de paiement expiré',
  },
  message: {
    id: 'layout.card.error.message',
    defaultMessage:
      'Attention, pour continuer de bénéficier de votre suivi, vous devez renseigner un nouveau moyen de paiement.',
  },
  buttonText: {
    id: 'layout.card.error.buttonText',
    defaultMessage: 'Mise à jour',
  },
});
