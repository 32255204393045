import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import NextLinkWithNoCss from '@/components/NextLinkWithNoCss/NextLinkWithNoCss';

import Facebook from './Svg/Facebook';
import Instagram from './Svg/Instagram';
import Tiktok from './Svg/Tiktok';
import Youtube from './Svg/Youtube';
import messages from './messages';

import useStyles from './styles';

const socialNetworksLinks = {
  facebook: 'https://www.facebook.com/lessherpas/',
  youtube: 'https://www.youtube.com/c/LesSherpas',
  instagram: 'https://www.instagram.com/les_sherpas/',
  tiktok: 'https://www.tiktok.com/@lessherpas_parents',
};

const BottomFooter: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({
  className,
}) => {
  const { classes, cx } = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={cx(classes.bottomFooter, className)}>
      <Typography variant="bodyMd" component="span" className={classes.text}>
        <FormattedMessage {...messages.withLove} />
      </Typography>
      <div className={classes.svg}>
        <NextLinkWithNoCss
          href={socialNetworksLinks.facebook}
          target="_blank"
          aria-label={formatMessage(messages.ariaLabel, {
            socialNetwork: 'facebook',
          })}
        >
          <Facebook />
        </NextLinkWithNoCss>
        <NextLinkWithNoCss
          href={socialNetworksLinks.instagram}
          target="_blank"
          aria-label={formatMessage(messages.ariaLabel, {
            socialNetwork: 'instagram',
          })}
        >
          <Instagram />
        </NextLinkWithNoCss>
        <NextLinkWithNoCss
          href={socialNetworksLinks.youtube}
          target="_blank"
          aria-label={formatMessage(messages.ariaLabel, {
            socialNetwork: 'youtube',
          })}
        >
          <Youtube />
        </NextLinkWithNoCss>
        <NextLinkWithNoCss
          href={socialNetworksLinks.tiktok}
          target="_blank"
          aria-label={formatMessage(messages.ariaLabel, {
            socialNetwork: 'tiktok',
          })}
        >
          <Tiktok />
        </NextLinkWithNoCss>
      </div>
    </div>
  );
};

export default BottomFooter;
