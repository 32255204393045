import {
  AuthApi,
  Gender,
  IAddress,
  PermissionResourceAndLevel,
  UserInfos,
  UserType,
} from '@les-sherpas/sherpas-toolbox';

class Authentication {
  constructor(
    authData?: AuthApi['private']['getAuthenticatedUserInfos']['response']
  ) {
    if (authData) {
      this.id = authData.id;
      this.userType = authData.userType;
      this.firstName = authData.firstName;
      this.lastName = authData.lastName;
      this.email = authData.email;
      this.photoUrl = authData.photoUrl ?? undefined;
      this.phoneNumber = authData.phoneNumber;
      this.gender = authData.gender;
      this.address = authData.address;
      this.shouldUseInvoiceReceiver = authData.shouldUseInvoiceReceiver;
      this.invoiceFirstName = authData.invoiceFirstName;
      this.invoiceLastName = authData.invoiceLastName;
      this.invoiceEmail = authData.invoiceEmail;
      this.invoiceAddress = authData.invoiceAddress;
      this.isEmailVerified = authData.isEmailVerified;
      this.createdDate = authData.createdDate;
      this.isMainUser = authData.isMainUser;
      this.mainUser = authData.mainUser;
      this.groupUsers = authData.groupUsers;
      this.permissions = authData.permissions;
      this.canBeSponsored = authData.canBeSponsored;
    }
  }

  public readonly id?: number;

  public readonly userType?: UserType;

  public readonly firstName?: string;

  public readonly lastName?: string;

  public readonly photoUrl?: string;

  public readonly gender?: Gender;

  public readonly email?: string;

  public readonly phoneNumber?: string;

  public readonly address?: IAddress;

  public readonly shouldUseInvoiceReceiver?: boolean;

  public readonly invoiceFirstName?: string;

  public readonly invoiceLastName?: string;

  public readonly invoiceEmail?: string;

  public readonly invoiceAddress?: IAddress;

  public readonly isEmailVerified?: boolean;

  public readonly createdDate?: Date;

  public isMainUser: boolean;

  public mainUser: UserInfos | null;

  public groupUsers: UserInfos[];

  public permissions: PermissionResourceAndLevel[];

  public readonly canBeSponsored: boolean;
}

export default Authentication;
