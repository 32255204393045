import { useCallback } from 'react';

import useLocalStorage from '@/hooks/useLocalStorage';

export type SponsoringStorageValue = {
  sponsoringCode: string;
  sponsorFirstName: string;
};

const useLocalStorageSponsor = () => {
  const { getItem, setItem, removeItem } =
    useLocalStorage<SponsoringStorageValue>('sponsor');

  const setSponsorInLocalStorage = useCallback(
    (sponsor: SponsoringStorageValue) => {
      setItem(sponsor);
    },
    [setItem]
  );

  const getSponsorInLocalStorage = useCallback(() => {
    const item = getItem();
    return item;
  }, [getItem]);

  return {
    getSponsorInLocalStorage,
    setSponsorInLocalStorage,
    removeSponsorInLocalStorage: removeItem,
  };
};

export default useLocalStorageSponsor;
