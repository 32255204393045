import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/BaseSvg';

const IconPaperAirplane: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <path d="M17.4472 10.8944C17.786 10.725 18 10.3788 18 10C18 9.62124 17.786 9.27498 17.4472 9.10558L3.44721 2.10558C3.09251 1.92823 2.66653 1.97724 2.36136 2.2305C2.0562 2.48376 1.92953 2.89342 2.03848 3.27473L3.46704 8.27472C3.5897 8.70402 3.98209 9 4.42857 9L9 9C9.55228 9 10 9.44771 10 10C10 10.5523 9.55228 11 9 11L4.42857 11C3.98209 11 3.58971 11.296 3.46705 11.7253L2.03848 16.7253C1.92953 17.1066 2.0562 17.5163 2.36136 17.7695C2.66653 18.0228 3.09251 18.0718 3.44721 17.8944L17.4472 10.8944Z" />
  </BaseSvg>
);

export default IconPaperAirplane;
