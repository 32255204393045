import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

type Props = { width: number; height: number; isSquared: boolean };

export default makeStyles<Props>()(
  ({ palette }, { width, height, isSquared }) => ({
    photo: {
      borderRadius: rem(32),
      objectFit: 'cover',
      aspectRatio: '1',
      border: `2px solid ${palette.common.white}`,
    },
    avatarTextContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: rem(width),
      height: rem(height),
      backgroundColor: palette.primary.main,
      borderRadius: isSquared ? rem(8) : '100%',
      aspectRatio: '1/1',
      border: `2px solid ${palette.common.white}`,
    },
    avatarText: {
      color: palette.common.white,
    },
    root: {
      width: rem(width),
      height: rem(height),
    },
  })
);
