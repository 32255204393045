/* eslint-disable react/no-unused-prop-types */
import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import Button from '@/designSystem/Button/Button';

import useStyles from './styles';

type CloseProps = {
  onClose: () => void;
  shouldDisplayLoginForm?: boolean;
};
export type ModalHeaderCompound = {
  ImageOrIcon: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>>;
  TitleWithCloseButton: FC<PropsWithChildren<CloseProps>>;
  TitleWithCloseIcon: FC<PropsWithChildren<CloseProps>>;
};

const ImageOrIcon: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({
  children,
  className,
}) => {
  const { classes, cx } = useStyles({});
  return <span className={cx(classes.img, className)}>{children}</span>;
};

const TitleWithCloseButton: FC<PropsWithChildren<CloseProps>> = ({
  onClose,
  children,
}) => {
  const { classes } = useStyles({});
  return (
    <div className={classes.titleWithCloseButton}>
      {children}
      <Button variant="tertiary" onClick={onClose} size="medium" icon="x" />
    </div>
  );
};

const TitleWithCloseIcon: FC<PropsWithChildren<CloseProps>> = ({
  children,
  onClose,
  shouldDisplayLoginForm = false,
}) => {
  const { classes } = useStyles({ shouldDisplayLoginForm });
  return (
    <div className={classes.titleWithCloseIcon}>
      {children}
      <IconButton onClick={onClose} size="medium" className={classes.icon}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};

const ModalHeader: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> &
  ModalHeaderCompound = ({ children, className }) => {
  const { classes, cx } = useStyles({});
  return <div className={cx(classes.header, className)}>{children}</div>;
};

ModalHeader.ImageOrIcon = ImageOrIcon;
ModalHeader.TitleWithCloseIcon = TitleWithCloseIcon;
ModalHeader.TitleWithCloseButton = TitleWithCloseButton;

export default ModalHeader;
