import { FC, PropsWithChildren } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Image from 'next/image';

import { Variant } from '@/types/Variant';

import useStyles from './styles';

type Props = {
  variant: Variant;
};

const VariantIcon: FC<PropsWithChildren<Props>> = ({ variant }) => {
  const { classes } = useStyles({ variant });
  let Icon;
  switch (variant) {
    case 'Error':
      Icon = <ErrorOutlineIcon />;
      break;
    case 'Information':
      Icon = <InfoOutlinedIcon />;
      break;
    case 'Confirmation':
      Icon = <CheckCircleOutlineIcon />;
      break;
    case 'Warning':
      Icon = <WarningRoundedIcon />;
      break;
    default: {
      Icon = (
        <Image
          alt="badge check"
          src="/images/alerts/badgeIcon.png"
          width={24}
          height={24}
        />
      );
    }
  }
  return <div className={classes.root}>{Icon}</div>;
};

export default VariantIcon;
