import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';

import useAuthentication from '@/components/Authentication/useAuthentication';
import LoginSignUpModal from '@/Organisms/LoginSignUpModal/LoginSignUpModal';
import useNavigation from '@/shared/Hooks/useNavigation';

const removeParamFromUrl = ({
  url,
  paramName,
}: {
  url: URL;
  paramName: string;
}): URL => {
  url.searchParams.delete(paramName);
  return url;
};

const signupQueryParamName = 'signup';

const GlobalSignupModal: FC = () => {
  const { authentication } = useAuthentication();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isOpenSignupModal, setIsOpenSignupModal] = useState(false);
  const searchParams = useSearchParams();
  const { urls, replaceToUnsafe } = useNavigation();

  useEffect(() => {
    if (
      isFirstLoad &&
      searchParams.has(signupQueryParamName) &&
      authentication?.isAuthenticated === false
    ) {
      setIsOpenSignupModal(searchParams.get(signupQueryParamName) === 'true');
      replaceToUnsafe(
        removeParamFromUrl({
          url: new URL(window.location.href),
          paramName: signupQueryParamName,
        })
      );
      setIsFirstLoad(false);
    }
  }, [searchParams, isFirstLoad]);

  return (
    <>
      {isOpenSignupModal && (
        <LoginSignUpModal
          isOpen={isOpenSignupModal}
          initialVariant="signup"
          origin="landingNavbar"
          drawerExitUrl={urls.search()}
          onClose={() => setIsOpenSignupModal(false)}
        />
      )}
    </>
  );
};

export default GlobalSignupModal;
