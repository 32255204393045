import { FC } from 'react';
import Image from 'next/image';

import { IconProps } from '@/Atoms/Icons/Icons.type';
import themeFoundation from '@/designSystem/theme';
import { getImageFromCloudImage } from '@/utils/cloudFrontLoader';

const SherpasIcon: FC<IconProps> = ({
  size = 20,
  color = themeFoundation.palette.neutral.dark,
}) => (
  <Image
    width={size}
    height={size}
    color={color}
    src="https://sherpas-prod.s3.eu-west-3.amazonaws.com/assets/sherpas-logo-mobile-mkt.webp"
    alt="Cours particuliers et soutien scolaire avec Les Sherpas"
    loading="eager"
    loader={getImageFromCloudImage}
  />
);

export default SherpasIcon;
