import { FC, HTMLAttributes, PropsWithChildren, useMemo } from 'react';
import { getInitials } from '@les-sherpas/sherpas-toolbox';
import { Typography } from '@mui/material';
import Image from 'next/image';

import { Typo } from '@/designSystem/theme';
import { getImageFromCloudImage } from '@/utils/cloudFrontLoader';

import useStyles from './styles';

type Props = {
  photoUrl?: string;
  width: number;
  height: number;
  firstName: string;
  lastName: string;
  typoVariant?: Typo;
  isSquared?: boolean;
  alt?: string;
};

const Avatar: FC<PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>> = ({
  photoUrl = null,
  width,
  height,
  firstName,
  lastName,
  className,
  typoVariant = 'bodyLgBold',
  isSquared = false,
  alt = 'Photo de Profil',
}) => {
  const { classes, cx } = useStyles({ width, height, isSquared });
  const hasOnlyFirstNameInitials = useMemo(() => width <= 28, [width]);
  const initials = getInitials({
    firstName,
    lastName: hasOnlyFirstNameInitials ? '' : lastName,
  });

  return (
    <div className={cx(classes.root, className)}>
      {!photoUrl ? (
        <div className={classes.avatarTextContainer}>
          <Typography
            variant={typoVariant}
            component="span"
            className={classes.avatarText}
          >
            {initials}
          </Typography>
        </div>
      ) : (
        <Image
          className={classes.photo}
          src={photoUrl}
          width={width}
          height={height}
          loader={getImageFromCloudImage}
          alt={alt}
        />
      )}
    </div>
  );
};

export default Avatar;
