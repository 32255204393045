/* eslint-disable no-unused-vars */
import { createContext, useContext } from 'react';
import {
  AcceptRelationPayload,
  AddConversationPayload,
  ChatApi,
  ChatCourse,
  ChatCourseScheduled,
  ChatDocumentType,
  ChatRoomApi,
  ChatSocketClientToServerEvents,
  ChatSocketServerToClientEvents,
  ChatSubscriptionProposal,
  CourseInfos,
  CourseStatusType,
  DeleteConversationPayload,
  Message,
  NewCourseHistoryPayload,
  PackProposal,
  PayCourse,
  RemoveChatSubscriptionProposal,
  SubscriptionPaymentPauseReason,
  SubscriptionStatusType,
  ToogleCanUpdateChatRoom,
  UpdateConversationPayload,
} from '@les-sherpas/sherpas-toolbox';
import { Socket } from 'socket.io-client';

export type ServerToClientEvents = {
  error: (error: Error) => void;
  [ChatSocketServerToClientEvents.ReceiveMessage]: (
    newMessage: Message
  ) => Promise<void>;
  [ChatSocketServerToClientEvents.AddConversation]: (
    conversation: AddConversationPayload
  ) => void;
  [ChatSocketServerToClientEvents.UpdateConversation]: (
    conversation: UpdateConversationPayload
  ) => void;
  [ChatSocketServerToClientEvents.DeleteConversation]: (
    conversation: DeleteConversationPayload
  ) => void;
  [ChatSocketServerToClientEvents.HasPendingDirtyText]: ({
    chatRoomId,
    hasPendingDirtyText,
  }: {
    chatRoomId: number;
    hasPendingDirtyText: boolean;
  }) => void;
  [ChatSocketServerToClientEvents.UpdateCourse]: (
    payload: ChatCourseScheduled
  ) => void;
  [ChatSocketServerToClientEvents.AddAcceptedCourseToDetails]: (
    payload: ChatCourseScheduled
  ) => void;
  [ChatSocketServerToClientEvents.RemoveCourseFromDetails]: (payload: {
    courseId: number;
    chatRoomId: number;
  }) => void;
  [ChatSocketServerToClientEvents.UpdateHourGlassHistory]: (payload: {
    courseId: number;
    duration: number;
    date: Date;
    status: CourseStatusType;
  }) => void;
  [ChatSocketServerToClientEvents.UpdateHourGlassRemainingMinutes]: (payload: {
    chatRoomId: number;
    remainingMinutes: number;
  }) => void;
  [ChatSocketServerToClientEvents.RemoveAcceptedCourseFromConversation]: (payload: {
    chatRoomId: number;
    courseId: number;
  }) => void;
  [ChatSocketServerToClientEvents.AddAcceptedCourseToConversation]: (
    payload: ChatCourse
  ) => void;
  [ChatSocketServerToClientEvents.AcceptRelation]: (
    payload: AcceptRelationPayload
  ) => void;
  [ChatSocketServerToClientEvents.NewCourse]: (
    payload: NewCourseHistoryPayload
  ) => void;
  [ChatSocketServerToClientEvents.NewChatRoom]: (
    payload: ChatRoomApi['private']['getChatRoomSummaries']['response'][number]
  ) => void;
  [ChatSocketServerToClientEvents.RefuseRelation]: (
    payload: ChatRoomApi['private']['getChatRoomSummaries']['response'][number]
  ) => void;
  [ChatSocketServerToClientEvents.ExpireRelation]: (
    payload: ChatRoomApi['private']['getChatRoomSummaries']['response'][number]
  ) => void;
  [ChatSocketServerToClientEvents.ReminderCourse]: (
    payload: ChatCourse
  ) => void;
  [ChatSocketServerToClientEvents.NewDocument]: (
    payload: ChatDocumentType
  ) => void;
  [ChatSocketServerToClientEvents.CanUpdateChatRoom]: (
    payload: ToogleCanUpdateChatRoom
  ) => void;
  [ChatSocketServerToClientEvents.ArchiveChatRoom]: (payload: {
    chatRoomId: number;
  }) => void;
  [ChatSocketServerToClientEvents.NotificationCount]: (count: number) => void;
  [ChatSocketServerToClientEvents.IsPaidCourse]: ({
    courseId,
    chatRoomId,
  }: PayCourse) => void;
  [ChatSocketServerToClientEvents.UpdateSubscription]: (
    subscription: NonNullable<
      ChatRoomApi['private']['getChatRoomInfos']['response']['subscription']
    >
  ) => void;
  [ChatSocketServerToClientEvents.AdminUpdateCourse]: (
    payload: Partial<CourseInfos>
  ) => void;
  [ChatSocketServerToClientEvents.NewSubscriptionProposal]: (
    payload: ChatSubscriptionProposal
  ) => void;
  [ChatSocketServerToClientEvents.PackProposal]: (
    payload: PackProposal
  ) => void;
  [ChatSocketServerToClientEvents.RemovePackProposal]: (
    payload: PackProposal
  ) => void;
  [ChatSocketServerToClientEvents.RemoveAllSubscriptionProposal]: (payload: {
    chatRoomId: number;
  }) => void;
  [ChatSocketServerToClientEvents.RemoveSubscriptionProposal]: (
    payload: RemoveChatSubscriptionProposal
  ) => void;
  [ChatSocketServerToClientEvents.SetSubscriptionStatus]: (payload: {
    status: SubscriptionStatusType;
    paymentPauseReason: SubscriptionPaymentPauseReason;
  }) => void;
};

export type ClientToServerEvents = {
  [ChatSocketClientToServerEvents.SendMessage]: (
    data: ChatApi['webSocket']['sendMessage']['messageBody']
  ) => void;
  [ChatSocketClientToServerEvents.HasSeenChatRoom]: ({
    chatRoomId,
  }: ChatApi['webSocket']['hasSeenChatRoom']['messageBody']) => void;
};

type SocketProps = {
  socket?: Socket<ServerToClientEvents, ClientToServerEvents>;
  notificationsCount?: number;
  isSocketConnected?: boolean;
};

const SocketContext = createContext<SocketProps>({});

export const SocketProvider = SocketContext.Provider;

export const useSocketContext = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error(
      "le context ne peut être utilisé si le provider SocketProvider n'a pas été setté"
    );
  }
  return context;
};
