import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export const useIsMobile = () => {
  const theme = useTheme();
  return !useMediaQuery(
    `@media (min-width:${rem(theme.breakpoints.values.md)})`,
    {}
  );
};

export const useIsDesktop = () => {
  const theme = useTheme();

  return useMediaQuery(
    `@media (min-width:${rem(theme.breakpoints.values.lg)})`
  );
};

export const useResponsiveClasses = makeStyles()(({ breakpoints }) => ({
  mobile: {
    display: 'flex',
    [breakpoints.up('md')]: {
      display: 'none',
    },
  },
  notMobile: {
    display: 'none',
    [breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  desktop: {
    display: 'none',
    [breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}));
