import { QueryClient, QueryKey } from '@tanstack/react-query';
import axios from 'axios';
import server from 'config';

/** From  https://react-query.tanstack.com/guides/default-query-function */
export async function defaultQueryFn<T>({
  queryKey,
}: {
  queryKey: QueryKey;
}): Promise<T> {
  try {
    const url = Array.isArray(queryKey) ? queryKey[0] : queryKey;
    const { data } = await axios.get<T>(`${server}${url}`, {
      withCredentials: true,
      validateStatus: (status) => status === 200 || status === 201,
    });
    return data;
  } catch (e) {
    return undefined;
  }
}

export const createQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: defaultQueryFn,
        refetchOnWindowFocus: false,
      },
    },
  });
