import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import { UserVariant } from '@/Molecules/User/User.type';

type StyleProps = {
  variant: UserVariant;
  isDropdownOpen: boolean;
  isWithDropDown: boolean;
};

const dropDownPadding = rem(16);

export default makeStyles<StyleProps>()(
  ({ palette }, { variant, isWithDropDown, isDropdownOpen }) => ({
    avatarContainer: {
      width: 'fit-content',
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      gap: rem(8),
      cursor: isWithDropDown ? 'pointer' : 'initial',
      borderRadius: rem(32),
      padding: rem(4),
      backgroundColor: palette.neutral[900],
      position: 'relative',
      '&:focus': {
        outline: 'none',
      },
    },
    name: {
      marginRight:
        // eslint-disable-next-line no-nested-ternary
        variant === 'bodyLg' ? rem(12) : variant === 'bodyMd' ? rem(4) : 0,
    },
    iconOrientation: {
      color: palette.neutral[500],
      padding: 0,
      marginRight: rem(12),
      transform: isDropdownOpen && 'rotate(180deg)',
    },
    logout: {
      color: palette.supporting.red.main,
    },
    separator: {
      width: `calc(100% + ${dropDownPadding} * 2)`,
      margin: `0 -${dropDownPadding}`,
      border: 'none',
      height: rem(1),
      backgroundColor: palette.neutral[800],
    },
  })
);
