import { FC, PropsWithChildren } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type Props = {
  variant: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  isUp?: boolean;
  isOnly?: boolean;
};

const Hidden: FC<PropsWithChildren<Props>> = ({
  children,
  variant,
  isUp,
  isOnly,
}) => {
  const { breakpoints } = useTheme();
  let query = breakpoints.down(variant);
  if (isUp) {
    query = breakpoints.up(variant);
  }
  if (isOnly) {
    query = breakpoints.only(variant);
  }
  const isHidden = useMediaQuery(query);
  return <>{!isHidden && children}</>;
};

export default Hidden;
