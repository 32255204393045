import { FC } from 'react';
import dynamic from 'next/dynamic';

import NavbarGuestUserDesktop from '@/shared/Navbar/Variants/NavbarGuestUser/NavbarGuestUserDesktop/NavbarGuestUserDesktop';
import { useIsMobile, useResponsiveClasses } from '@/utils/useResponsive';

const NavbarGuestUserMobile = dynamic(
  () =>
    import(
      '@/shared/Navbar/Variants/NavbarGuestUser/NavbarGuestUserMobile/NavbarGuestUserMobile'
    ),
  { ssr: true }
);

const NavbarGuestUser: FC = () => {
  const isMobile = useIsMobile();
  const { classes: responsiveClasses } = useResponsiveClasses();

  return isMobile ? (
    <NavbarGuestUserMobile />
  ) : (
    <div className={responsiveClasses.desktop}>
      <NavbarGuestUserDesktop />
    </div>
  );
};

export default NavbarGuestUser;
