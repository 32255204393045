import { makeStyles } from 'tss-react/mui';

import noCssButton from '@/styles/noCssButton';

export default makeStyles()(({ palette: { supporting, neutral } }) => ({
  alreadyRegister: {
    color: neutral['500'],
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  connect: {
    color: supporting.blue.main,
  },
  button: { ...noCssButton },
}));
