import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  root: {
    height: rem(44),
    overflow: 'hidden',
    display: 'flex',
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.neutral[700]}`,
    borderRadius: `${rem(8)}`,
    alignItems: 'center',
  },
  input: {
    ...theme.typography.bodyMd,
    width: '100%',
    order: 1,
    padding: rem(12),
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.palette.neutral[600],
    },
    '&[type=text], &[type=password]': {
      color: theme.palette.neutral[200],
    },
    /** FOR FIXING ISSUE WITH FOCUS FOR IPHONE
     * https://webdevdesigner.com/q/disable-auto-zoom-in-input-text-tag-safari-on-iphone-70615/
     */
    [theme.breakpoints.down('md')]: {
      fontSize: rem(16),
    },
  },
}));
