import axios from 'axios';
import server from 'config';

const logout = async () => {
  try {
    const { status } = await axios.get(`${server}auth/logout`, {
      withCredentials: true,
    });
    return status === 200 || status === 201;
  } catch (e) {
    return false;
  }
};

export default logout;
