import { FC } from 'react';

import NextLinkWithNoCss from '@/components/NextLinkWithNoCss/NextLinkWithNoCss';
import { LinkProps } from '@/Molecules/User/Dropdown/Link/Link';

import useStyles, { StyleProps } from './styles';

type Props = LinkProps & StyleProps;

const ItemNavigationLink: FC<Props> = ({
  href,
  children,
  onClick,
  isActive = false,
}) => {
  const { classes } = useStyles({ isActive });
  return (
    <NextLinkWithNoCss
      href={href}
      onClick={onClick}
      className={classes.link}
      data-mozza="navigationLink"
    >
      {children}
    </NextLinkWithNoCss>
  );
};

export default ItemNavigationLink;
