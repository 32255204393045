import { ChangeEvent, FC, ReactEventHandler } from 'react';
import { UserType } from '@les-sherpas/sherpas-toolbox';

import EmailButton from '@/Molecules/EmailButton/EmailButton';
import GafamButton from '@/Molecules/GafamButton/GafamButton';
import { LoginSignUpVariant } from '@/Organisms/LoginSignUpModal/LoginSignUp.type';
import Modal from '@/Organisms/Modal/Modal';
import { Provider } from '@/types/provider';

type Props = {
  variant: LoginSignUpVariant;
  userType: UserType;
  onEmailButtonClick: ReactEventHandler;
  onGafamButtonClick: (_e: ChangeEvent<HTMLElement>, _p: Provider) => void;
};

const LoginSignUpModalBody: FC<Props> = ({
  variant,
  userType,
  onEmailButtonClick,
  onGafamButtonClick,
}) => (
  <Modal.Body>
    <GafamButton
      userType={userType}
      provider="google"
      variant={variant}
      onClick={(e) => onGafamButtonClick(e, 'google')}
      version="v2"
    />
    <GafamButton
      userType={userType}
      provider="facebook"
      variant={variant}
      onClick={(e) => onGafamButtonClick(e, 'facebook')}
      version="v2"
    />
    <EmailButton variant={variant} onClick={onEmailButtonClick} />
  </Modal.Body>
);

export default LoginSignUpModalBody;
