import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette }) => ({
  container: {
    width: rem(20),
    height: rem(20),
    transform: 'rotate(-90deg)',
  },
  progressBarBackground: {
    stroke: palette.neutral[800],
    strokeWidth: 6,
    fill: 'transparent',
  },
  progressBarValue: {
    stroke: palette.supporting.green[300],
    strokeWidth: 6,
    strokeLinecap: 'butt',
    fill: 'transparent',
  },
}));
