import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles<{ isRow: boolean }>()((_, { isRow }) => ({
  buttonFooter: {
    display: 'flex',
    flexFlow: isRow ? 'row' : 'column',
    gap: rem(8),
    alignItems: isRow && 'center',
  },
  label: {
    textAlign: 'center',
  },
}));
