import {
  ComponentProps,
  FC,
  MouseEventHandler,
  ReactEventHandler,
} from 'react';
import { Typography } from '@mui/material';

import Icon from '@/designSystem/Icon/Icon';

import useStyles from './styles';

type BaseButtonSize = 'small' | 'medium' | 'large';
type BaseButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'ghost'
  | 'link';
type BaseButtonColor = 'brand' | 'danger';
type BaseButtonCornerSide = 'left' | 'right' | 'all' | 'none';

export type BaseButtonProps = {
  label?: string;
  icon?: ComponentProps<typeof Icon>['icon'];
  iconColor?: ComponentProps<typeof Icon>['color'];
  size?: BaseButtonSize;
  variant?: BaseButtonVariant;
  color?: BaseButtonColor;
  cornerSide?: BaseButtonCornerSide;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: ReactEventHandler & MouseEventHandler<HTMLButtonElement>;
};

const getTypographyVariant = (size: BaseButtonProps['size']) => {
  switch (size) {
    case 'small':
      return 'bodySmBold';
    case 'medium':
      return 'bodyMdBold';
    case 'large':
    default:
      return 'bodyLgBold';
  }
};

const getIconSize = (size: BaseButtonProps['size']) => {
  switch (size) {
    case 'small':
      return 'medium';
    case 'medium':
    case 'large':
    default:
      return 'large';
  }
};

const BaseButton: FC<BaseButtonProps> = ({
  size = 'medium',
  label,
  icon,
  iconColor,
  variant = 'primary',
  color = 'brand',
  isDisabled = false,
  isFullWidth = false,
  cornerSide = 'none',
  type = 'button',
  onClick,
}) => {
  const { classes } = useStyles({
    size,
    variant,
    isDisabled,
    isFullWidth,
    color,
    withIcon: !!icon,
    withText: !!label,
    cornerSide,
  });

  return (
    <button
      className={classes.container}
      // eslint-disable-next-line react/button-has-type
      type={type}
      tabIndex={0}
      onClick={onClick}
      disabled={isDisabled}
    >
      {!!icon && (
        <Icon icon={icon} size={getIconSize(size)} color={iconColor} />
      )}
      {!!label && (
        <Typography
          className={classes.label}
          component="span"
          variant={getTypographyVariant(size)}
        >
          {label}
        </Typography>
      )}
    </button>
  );
};

export default BaseButton;
