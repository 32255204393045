import { makeStyles } from 'tss-react/mui';

export default makeStyles<{ length: number }>()((theme, { length }) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexFlow: 'column',
    borderRadius: '12px',
    maxHeight: '270px',
    overflow: length > 5 ? 'hidden scroll' : 'hidden',
    padding: '12px',
  },
  button: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    width: '100%',
    textAlign: 'inherit',
    display: 'flex',
    alignItems: 'center',
    '&:focus': {
      backgroundColor: theme.palette.neutral.light,
    },
  },
  text: {
    width: '100%',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'bold',
    padding: '4px 8px',
    margin: '4px 0',
    '&:hover': {
      backgroundColor: theme.palette.neutral.light,
    },
  },
  noOptionText: {
    width: '100%',
    fontSize: '16px',
    lineHeight: '24px',
    padding: '4px 8px',
    margin: '4px 0',
    color: theme.palette.text.secondary,
  },
  icon: {
    display: 'flex',
    margin: '8px',
    color: theme.palette.text.secondary,
  },
}));
