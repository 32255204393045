import { defineMessages } from 'react-intl';

export default defineMessages({
  description: {
    id: 'ds.signup.modal.teacher.infos.description',
    defaultMessage: `{message, select, 
        Certified {{firstName} est un Sherpa certifié}
        QuickAnswer {{firstName} répond rapidement}
        CertifiedQuickAnswer {{firstName} est un Sherpa certifié et répond rapidement}
        other {}
    }`,
  },
  ratings: {
    id: 'ds.signup.modal.teacher.infos.ratings',
    defaultMessage: `{numberOfRecommandation} avis`,
  },
  accessibilite: {
    id: 'ds.signup.modal.teacher.infos.ratings',
    defaultMessage: `note de {rating} sur 5`,
  },
});
