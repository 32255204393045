import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/BaseSvg';

const IconSpotify: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <path
      d="M9.99979 1.66669C5.39754 1.66669 1.6665 5.39771 1.6665 10.0001C1.6665 14.6026 5.39754 18.3334 9.99979 18.3334C14.6025 18.3334 18.3332 14.6026 18.3332 10.0001C18.3332 5.39801 14.6025 1.66709 9.99969 1.66709L9.99979 1.66669ZM13.8214 13.6858C13.6721 13.9306 13.3517 14.0082 13.1069 13.858C11.1503 12.6628 8.68725 12.3922 5.78653 13.0549C5.507 13.1186 5.22837 12.9435 5.16469 12.6638C5.1007 12.3842 5.27514 12.1056 5.55536 12.0419C8.72974 11.3166 11.4526 11.6289 13.6492 12.9713C13.894 13.1216 13.9716 13.441 13.8214 13.6858ZM14.8414 11.4167C14.6533 11.7224 14.2533 11.819 13.9478 11.6309C11.7078 10.254 8.29329 9.85528 5.64383 10.6595C5.30022 10.7633 4.93731 10.5697 4.83302 10.2267C4.72953 9.88304 4.92327 9.52082 5.26629 9.41633C8.29269 8.49803 12.0551 8.94285 14.6274 10.5236C14.9329 10.7117 15.0294 11.1116 14.8414 11.4167ZM14.9289 9.0539C12.2431 7.45862 7.81196 7.31194 5.24768 8.09022C4.8359 8.21511 4.40045 7.98265 4.27566 7.57087C4.15088 7.15889 4.38313 6.72372 4.7952 6.59853C7.73882 5.70491 12.6322 5.87756 15.7244 7.71327C16.0956 7.93309 16.217 8.41145 15.9971 8.78134C15.7781 9.15173 15.2985 9.27383 14.9293 9.0539H14.9289Z"
      fill="#1ED760"
    />
  </BaseSvg>
);

export default IconSpotify;
