import { FC } from 'react';

import NavbarContextProvider from '@/shared/Navbar/context/NavbarContextProvider';
import NavBarVariant from '@/shared/Navbar/context/NavbarVariant';

const Navbar: FC = () => (
  <NavbarContextProvider>
    <NavBarVariant />
  </NavbarContextProvider>
);

export default Navbar;
