import { FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery, useTheme } from '@mui/material';

import { BLOG } from '@/data/navbar/navButtons';
import SherpasLogo from '@/designSystem/Sherpas/SherpasLogo';
import BottomFooter from '@/Molecules/Footer/BottomFooter/BottomFooter';
import ButtonFooter from '@/Molecules/Footer/ButtonFooter/ButtonFooter';
import SectionFooter from '@/Molecules/Footer/SectionFooter/SectionFooter';
import useNavigation from '@/shared/Hooks/useNavigation';

import messages from './messages';

import useStyles from './styles';

const Footer: FC<PropsWithChildren<unknown>> = () => {
  const { classes } = useStyles();
  const { breakpoints } = useTheme();
  const isMoreThanMobile = useMediaQuery(breakpoints.up('md'));
  const isLessThanDesktop = useMediaQuery(breakpoints.down('lg'));
  const isTablet = isMoreThanMobile && isLessThanDesktop;
  const { urls } = useNavigation();

  const firstSectionLinks = [
    {
      label: <FormattedMessage {...messages.findSherpas} />,
      url: urls.search(),
      name: messages.findSherpas.defaultMessage,
    },
    {
      label: <FormattedMessage {...messages.whoAreWe} />,
      url: urls.blogAbout(),
      name: messages.whoAreWe.defaultMessage,
    },
    {
      label: <FormattedMessage {...messages.becomeSherpas} />,
      url: urls.givePrivateCourse(),
      name: messages.becomeSherpas.defaultMessage,
    },
    {
      label: <FormattedMessage {...messages.price} />,
      url: urls.zendeskPrice(),
      name: messages.price.defaultMessage,
    },
  ];
  const secondSectionLinks = [
    {
      label: <FormattedMessage {...messages.media} />,
      url: BLOG,
      name: messages.media.defaultMessage,
    },
    {
      label: <FormattedMessage {...messages.questions} />,
      url: urls.zendeskQuestions(),
      name: messages.questions.defaultMessage,
    },
    {
      label: <FormattedMessage {...messages.cities} />,
      url: urls.privateLessonsFrance(),
      name: messages.questions.defaultMessage,
    },
    {
      label: <FormattedMessage {...messages.subjects} />,
      url: urls.privateLessonsSubjects(),
      name: messages.questions.defaultMessage,
    },
  ];
  const thirdSectionLinks = [
    {
      label: <FormattedMessage {...messages.charteConfidentialite} />,
      url: urls.privacyPolicy(),
      name: messages.charteConfidentialite.defaultMessage,
    },
    {
      label: <FormattedMessage {...messages.legal} />,
      url: urls.legalNotice(),
      name: messages.legal.defaultMessage,
    },
    {
      label: <FormattedMessage {...messages.cgu} />,
      url: urls.legalNotice(),
      name: messages.cgu.defaultMessage,
    },
    {
      label: <FormattedMessage {...messages.cookies} />,
      url: '/',
      onClick: () => {
        window?.openAxeptioCookies();
      },
      name: messages.cookies.defaultMessage,
    },
  ];

  return (
    <footer className={classes.footer}>
      {isTablet && (
        <div className={classes.tabletTitle}>
          <SherpasLogo />
          <ButtonFooter isRow />
        </div>
      )}
      <div className={classes.sections}>
        {!isTablet && (
          <div className={classes.logo}>
            <SherpasLogo />
          </div>
        )}

        <SectionFooter
          title={<FormattedMessage {...messages.title} />}
          links={firstSectionLinks}
        />
        <SectionFooter
          title={<FormattedMessage {...messages.resources} />}
          links={secondSectionLinks}
        />
        <SectionFooter
          title={<FormattedMessage {...messages.company} />}
          links={thirdSectionLinks}
        />
        {!isTablet && <ButtonFooter className={classes.mobileButtonFooter} />}
      </div>
      <BottomFooter className={classes.bottomFooter} />
    </footer>
  );
};

export default Footer;
