import {
  FC,
  HTMLAttributes,
  MouseEventHandler,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import CancelRounded from '@mui/icons-material/CancelRounded';
import { IconButton, InputBase } from '@mui/material';

import { HighlightReturn } from '@/Organisms/SearchBar/utils';
import { Subject } from '@/types/subject';

import Options from '../DrawerOptions/DrawerOptions';

import useStyles from './styles';

type Props = {
  preSelectedData?: HighlightReturn[][];
  placeholder: string;
  ariaLabel?: string;
  Icon?: FC<PropsWithChildren<unknown>>;
  inputValue: string;
  setInputValue: Function;
  onOptionChange?: Function;
  optionOffset?: number;
  onTextChange?: Function;
  datas?: Subject[] | string[];
  cssOption?: string;
  name?: string;
  isReadOnly?: boolean;

  // TODO: clean after refacto NavbarSearch V2
  cssRoot?: string;
  cssPlaceholder?: string;
  cssInput?: string;
  cssIcon?: string;
  onIconButtonClick?: Function;
  onEnter?: Function;
  onClose?: MouseEventHandler<HTMLButtonElement>;
};

const Input: FC<
  PropsWithChildren<Props & HTMLAttributes<HTMLInputElement>>
> = ({
  preSelectedData,
  inputValue,
  setInputValue,
  placeholder,
  ariaLabel,
  Icon,
  datas,
  onOptionChange,
  onTextChange: onChange = () => null,
  cssOption,
  optionOffset = 0,
  name = 'defaultName',
  isReadOnly = false,
  cssRoot,
  cssPlaceholder,
  cssInput,
  cssIcon,
  onIconButtonClick,
  onEnter,
  onClose,
  ...rest
}) => {
  const inputRef = useRef<HTMLDivElement>();

  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [options, setOptions] = useState([[{ text: 'Aucun résultat' }]]);

  const { classes, cx } = useStyles({ isReadOnly });

  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef?.current && !inputRef?.current?.contains(event.target)) {
        setIsOptionOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputRef]);

  const getOptions = (inputValue: string) => {
    const dataOptions = onOptionChange(datas, inputValue);
    if (inputValue === '') {
      setOptions(preSelectedData);
    } else if (dataOptions?.length > 0) {
      setOptions(dataOptions);
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    getOptions(inputValue);
  }, [datas, inputValue]);

  const onInputChange = (e) => {
    const input = e.target.value;
    setInputValue(input);
    if (input !== '' && input.length >= optionOffset) {
      setIsOptionOpen(true);
    }
    if (input === '' && input.length < optionOffset) {
      setIsOptionOpen(false);
    }
  };

  const onKeyDown = (e) => {
    if (onEnter && e.key === 'Enter') {
      const input = e.target.value;
      onEnter(input);
      setIsOptionOpen(false);
    }
  };

  const onOptionClick = (option) => {
    setInputValue(option);
    onChange(option);
    setIsOptionOpen(false);
  };

  return (
    <div
      className={cssRoot ? cx(classes.root, cssRoot) : classes.root}
      ref={inputRef}
    >
      {onClose && (
        <IconButton
          aria-label="fermer"
          disableRipple
          disableFocusRipple
          size="large"
          onClick={onClose}
          classes={{ root: cx(classes.iconButton, classes.closeButton) }}
        >
          <CancelRounded />
        </IconButton>
      )}
      {Icon && (
        <IconButton
          aria-label="rechercher"
          disableRipple
          disableFocusRipple
          classes={{
            root: cssIcon
              ? cx(classes.iconButton, cssIcon)
              : classes.iconButton,
          }}
          size="large"
          onClick={() => onIconButtonClick(inputValue)}
        >
          <Icon />
        </IconButton>
      )}
      <InputBase
        className={cssInput ? cx(classes.input, cssInput) : classes.input}
        placeholder={placeholder}
        classes={{
          input: cssPlaceholder
            ? cx(classes.placeholder, cssPlaceholder)
            : classes.placeholder,
        }}
        onChange={onInputChange}
        onKeyDown={onKeyDown}
        value={inputValue}
        onFocus={() => {
          setIsOptionOpen(true);
          setOptions(preSelectedData);
        }}
        inputProps={{
          ...rest,
          'aria-label': `${ariaLabel}`,
          autoComplete: 'off',
          readOnly: isReadOnly,
        }}
        name={name}
      />
      {isOptionOpen && (
        <div className={`${classes.options} ${cssOption}`}>
          <Options options={options} onClick={onOptionClick} />
        </div>
      )}
    </div>
  );
};

export default Input;
