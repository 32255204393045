import { FC, HTMLAttributes, MouseEvent, PropsWithChildren } from 'react';
import NextLink from 'next/link';

import useStyles from './styles';

type Props = {
  href: string;
  isReplace?: boolean;
  onClick?: (_event: MouseEvent<HTMLElement, MouseEvent>) => void;
  target?: string;
} & HTMLAttributes<HTMLElement>;

const NextLinkWithNoCss: FC<PropsWithChildren<Props>> = ({
  href,
  onClick = () => null,
  isReplace = false,
  children,
  className,
  target = '_self',
  ...rest
}) => {
  const { classes, cx } = useStyles();
  return (
    <NextLink
      href={href}
      replace={isReplace}
      className={cx(classes.link, className)}
      onClick={onClick}
      target={target}
      {...rest}
    >
      {children}
    </NextLink>
  );
};

export default NextLinkWithNoCss;
