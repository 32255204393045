import { FC } from 'react';

import { DASHBOARD } from '@/data/navbar/navButtons';
import User from '@/Molecules/User/User';
import { HEADER_NAVIGATION_ID } from '@/shared/Navbar/constant';
import { useNavbarContext } from '@/shared/Navbar/context/NavbarContext';
import ItemNavigation from '@/shared/Navbar/Items/ItemNavigation/ItemNavigation';
import LinkSherpa from '@/shared/Navbar/Items/LinkSherpa/LinkSherpa';
import NavbarSearch from '@/shared/Navbar/Items/NavbarSearch/NavbarSearch';
import useCommonStyles from '@/shared/Navbar/styles';

const NavbarAuthUserDesktop: FC = () => {
  const { isLandingPage, photoUrl, firstName, lastName, route } =
    useNavbarContext();
  const { classes: commonClasses } = useCommonStyles({
    shouldAnimateNavBar: isLandingPage,
  });

  return (
    <header className={commonClasses.root} id={HEADER_NAVIGATION_ID}>
      <div className={commonClasses.navContainer}>
        <div className={commonClasses.navigationMenu}>
          <LinkSherpa href={DASHBOARD} />
          <NavbarSearch />
        </div>
        <div className={commonClasses.itemNavigation}>
          <ItemNavigation activeLink={route} />
          <User
            photoUrl={photoUrl}
            firstName={firstName}
            lastName={lastName}
            isWithDropDown
            showDropdownArrow
          />
        </div>
      </div>
    </header>
  );
};

export default NavbarAuthUserDesktop;
