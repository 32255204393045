import { AuthenticatedTeacherInfos } from '@les-sherpas/sherpas-toolbox';

class TeacherInfosClass {
  constructor(teacher?: AuthenticatedTeacherInfos) {
    if (teacher) {
      this.draftId = teacher.draftId;
      this.hasAnnounce = !!teacher.hasAnnounce;
      this.shouldBeOnboard = teacher.shouldBeOnboard;
      this.birthday = teacher.birthday;
      this.hasClickedOnTrainingAppointment =
        teacher.hasClickedOnTrainingAppointment;
      this.institution = teacher.institution;
      this.hasBookedOnTrainingAppointment =
        teacher.hasBookedOnTrainingAppointment;
    }
  }

  public readonly draftId?: number;

  public readonly hasAnnounce: boolean;

  public readonly shouldBeOnboard?: boolean;

  public readonly birthday?: Date;

  public readonly hasClickedOnTrainingAppointment?: boolean;

  public readonly hasBookedOnTrainingAppointment?: boolean;

  public readonly institution?: string;

  get hasDraftInProgress(): boolean {
    return this.draftId > 0;
  }
}

export default TeacherInfosClass;
