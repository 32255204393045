import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';

import NextLinkWithNoCss from '@/components/NextLinkWithNoCss/NextLinkWithNoCss';

export type LinkProps = {
  href: string;
  onClick?: () => void;
} & HTMLAttributes<HTMLSpanElement>;

const Link: FC<PropsWithChildren<LinkProps>> = ({
  href,
  children,
  className,
  onClick = () => null,
}) => (
  <NextLinkWithNoCss href={href} onClick={onClick}>
    <Typography variant="bodyLgBold" component="span" className={className}>
      {children}
    </Typography>
  </NextLinkWithNoCss>
);

export default Link;
