import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/BaseSvg';

const IconCreditCard: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <g clipPath="url(#clip0_5761_1525)">
      <path
        opacity="0.2"
        d="M18.331 16.9999H1.66897C1.4516 17.0018 1.23601 16.9595 1.0345 16.8755C0.832988 16.7914 0.649505 16.6673 0.494525 16.5101C0.339546 16.353 0.216107 16.1659 0.131256 15.9595C0.0464046 15.7532 0.00180381 15.5316 0 15.3075V4.69238C0.00180381 4.46826 0.0464046 4.24669 0.131256 4.04034C0.216107 3.83399 0.339546 3.64689 0.494525 3.48973C0.649505 3.33257 0.832988 3.20843 1.0345 3.12439C1.23601 3.04035 1.4516 2.99806 1.66897 2.99994H18.331C18.5484 2.99806 18.764 3.04035 18.9655 3.12439C19.167 3.20843 19.3505 3.33257 19.5055 3.48973C19.6605 3.64689 19.7839 3.83399 19.8687 4.04034C19.9536 4.24669 19.9982 4.46826 20 4.69238V15.3146C19.9945 15.766 19.8159 16.1968 19.5031 16.5126C19.1903 16.8285 18.7688 17.0037 18.331 16.9999ZM6.89655 7.14571C6.89655 6.8186 6.65517 6.55549 6.35862 6.55549H2.6069C2.31034 6.55549 2.06897 6.8186 2.06897 7.14571V9.52082C2.06897 9.84793 2.31034 10.111 2.6069 10.111H6.35862C6.65517 10.111 6.89655 9.84793 6.89655 9.52082V7.14571Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M17.2413 13.6667H15.1723C14.724 13.6667 14.4826 13.4534 14.4826 12.9556C14.4826 12.4578 14.724 12.2445 15.1723 12.2445H17.2413C17.6895 12.2445 17.9309 12.4578 17.9309 12.9556C17.9309 13.4534 17.6895 13.6667 17.2413 13.6667ZM13.1033 13.6667H11.0344C10.5861 13.6667 10.3447 13.4534 10.3447 12.9556C10.3447 12.4578 10.5861 12.2445 11.0344 12.2445H13.1033C13.5516 12.2445 13.793 12.4578 13.793 12.9556C13.793 13.4534 13.5516 13.6667 13.1033 13.6667ZM8.9654 13.6667H6.89643C6.44816 13.6667 6.20678 13.4534 6.20678 12.9556C6.20678 12.4578 6.44816 12.2445 6.89643 12.2445H8.9654C9.41368 12.2445 9.65505 12.4578 9.65505 12.9556C9.65505 13.4534 9.41368 13.6667 8.9654 13.6667ZM4.82747 13.6667H2.7585C2.31023 13.6667 2.06885 13.4534 2.06885 12.9556C2.06885 12.4578 2.31023 12.2445 2.7585 12.2445H4.82747C5.27574 12.2445 5.51712 12.4578 5.51712 12.9556C5.51712 13.4534 5.27574 13.6667 4.82747 13.6667Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_5761_1525">
        <rect width="20" height="14" fill="white" transform="translate(0 3)" />
      </clipPath>
    </defs>
  </BaseSvg>
);

export default IconCreditCard;
