import { makeStyles } from 'tss-react/mui';

type Props = {
  size: number;
};

export default makeStyles<Props>()(({ palette }, { size }) => ({
  common: {
    width: size,
    height: size,
  },
  neutral: {
    color: palette.neutral[500],
  },
  neutralEmphasis: {
    color: palette.neutral[300],
  },
  subtle: {
    color: palette.neutral[600],
  },
  accent: {
    color: palette.supporting.blue[500],
  },
  accentEmphasis: {
    color: palette.supporting.blue[300],
  },
  attention: {
    color: palette.supporting.amber[500],
  },
  attentionEmphasis: {
    color: palette.supporting.amber[300],
  },
  success: {
    color: palette.supporting.green[500],
  },
  successEmphasis: {
    color: palette.supporting.green[300],
  },
  danger: {
    color: palette.supporting.red[500],
  },
  dangerEmphasis: {
    color: palette.supporting.red[300],
  },
  brand: {
    color: palette.primary[400],
  },
  brandEmphasis: {
    color: palette.primary.main,
  },
  white: {
    color: palette.common.white,
  },
}));
