import { FC, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { UserType } from '@les-sherpas/sherpas-toolbox';

import useGafamConnection from '@/components/OnBoarding/GafamConnection/GafamConnection.hooks';
import useGafamConnectionV1 from '@/components/OnBoarding/GafamConnection/GafamConnectionV1.hooks';
import Button from '@/designSystem/Button/Button';
import { LoginSignUpVariant } from '@/Organisms/LoginSignUpModal/LoginSignUp.type';
import { Provider } from '@/types/provider';

import messages from './messages';

type Props = {
  userType: UserType;
  shouldRedirect?: boolean;
  shouldRegister?: boolean;
  provider: Provider;
  variant?: LoginSignUpVariant;
  onClick?: Function;
  version?: 'v1' | 'v2';
};

const GafamButton: FC<PropsWithChildren<Props>> = ({
  userType,
  shouldRedirect = false,
  shouldRegister = false,
  provider,
  variant = 'login',
  onClick = () => {},
  version = 'v1',
}) => {
  const { formatMessage } = useIntl();

  const { onClick: onGafamClick } = useGafamConnectionV1({
    provider,
    userType,
    shouldRedirect,
    shouldRegister,
  });

  const { onClick: onGafamClickV2 } = useGafamConnection({
    provider,
    variant,
  });

  const handleClick = () => {
    onClick();
    if (version === 'v2') {
      onGafamClickV2();
      return;
    }
    onGafamClick();
  };

  return (
    <Button
      isFullWidth
      variant="secondary"
      onClick={handleClick}
      icon={provider === 'google' ? 'google' : 'facebook'}
      label={formatMessage(messages.button, { provider, variant })}
    />
  );
};

export default GafamButton;
