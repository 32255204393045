import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';

import { Typo } from '@/designSystem/theme';

import useStyles from './styles';

export type BodyCompound = {
  Title: FC<PropsWithChildren<TitleProps>>;
  Description: FC<PropsWithChildren<unknown>>;
};

type TitleProps = {
  variant?: Typo;
};

const Title: FC<PropsWithChildren<TitleProps>> = ({
  children,
  variant = 'titleXs',
}) => {
  const { classes } = useStyles();
  return (
    <Typography className={classes.title} variant={variant} component="h5">
      {children}
    </Typography>
  );
};

const Description: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { classes } = useStyles();
  return (
    <Typography className={classes.description} variant="bodyLg" component="p">
      {children}
    </Typography>
  );
};

const ModalBody: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> &
  BodyCompound = ({ children, className }) => {
  const { classes, cx } = useStyles();
  return <div className={cx(classes.body, className)}>{children}</div>;
};

ModalBody.Title = Title;
ModalBody.Description = Description;

export default ModalBody;
