import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ds.footer.title',
    defaultMessage: 'Les Sherpas',
  },
  findSherpas: {
    id: 'ds.footer.find.sherpas',
    defaultMessage: 'Trouver un Sherpa',
  },
  whoAreWe: {
    id: 'ds.footer.who.are.we',
    defaultMessage: 'Qui sommes-nous ?',
  },
  becomeSherpas: {
    id: 'ds.footer.become.sherpas',
    defaultMessage: 'Donner des cours particuliers',
  },
  price: {
    id: 'ds.footer.price',
    defaultMessage: 'Prix',
  },
  resources: {
    id: 'ds.footer.resources',
    defaultMessage: 'Ressources',
  },
  media: {
    id: 'ds.footer.media',
    defaultMessage: 'Média',
  },
  questions: {
    id: 'ds.footer.questions',
    defaultMessage: 'Foire aux questions',
  },
  press: {
    id: 'ds.footer.press',
    defaultMessage: 'Dossier de presse',
  },
  forum: {
    id: 'ds.footer.forum',
    defaultMessage: 'Forum',
  },
  company: {
    id: 'ds.footer.company',
    defaultMessage: 'L’entreprise',
  },
  charteConfidentialite: {
    id: 'ds.footer.charte.confidentialite',
    defaultMessage: 'Charte de Confidentialité',
  },
  hire: {
    id: 'ds.footer.hire',
    defaultMessage: 'On recrute !',
  },
  legal: {
    id: 'ds.footer.legal',
    defaultMessage: 'Mentions légales',
  },
  cgu: {
    id: 'ds.footer.cgu',
    defaultMessage: 'CGU',
  },
  cookies: {
    id: 'ds.footer.cookies',
    defaultMessage: 'Mes préferences Cookies',
  },
  cities: {
    id: 'ds.footer.cities',
    defaultMessage: 'Cours par ville',
  },
  subjects: {
    id: 'ds.footer.subjects',
    defaultMessage: 'Cours par matière',
  },
});
