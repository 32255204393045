import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints }) => ({
  root: {
    backgroundColor: palette.neutral[900],
    borderRadius: rem(8),
    padding: rem(12),
  },
  input: {
    padding: 0,
    [breakpoints.up('lg')]: {
      width: rem(192),
    },
  },
  iconButton: {
    padding: 0,
    color: palette.neutral[200],
    order: 2,
  },
  placeholder: {
    padding: 0,
    '&::placeholder': {
      color: palette.neutral[500],
      fontWeight: 400,
      fontSize: rem(14),
      lineHeight: rem(20),
    },
  },
  searchPanel: {
    position: 'absolute',
    backgroundColor: palette.common.white,
    display: 'flex',
    inset: 0,
    padding: `${rem(16)} ${rem(12)}`,
  },
}));
