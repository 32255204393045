import { FC, PropsWithChildren, useMemo } from 'react';
import { TeacherApi, UserType } from '@les-sherpas/sherpas-toolbox';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import useAuthentication from '@/components/Authentication/useAuthentication';
import { useSocketContext } from '@/components/Socket/Socket.context';
import { INFLUENCER_MAMAN_URL } from '@/data/navbar/navButtons';
import { GET_MILESTONE_STATUS_URL } from '@/shared/API/apiGetMilestoneStatus';
import { NavbarContext } from '@/shared/Navbar/context/NavbarContext';

type Props = {};

const ROUTES_LANDING_PAGES = [
  '/mozza',
  '/',
  INFLUENCER_MAMAN_URL,
  '/cours/[subject]',
  '/cours/[subject]/[city]',
  '/cours/[subject]/online',
  '/cours/toute-matiere/[city]',
  '/cours/toute-matiere/france/[level]',
];

const NavbarContextProvider: FC<PropsWithChildren<Props>> = ({ children }) => {
  const { notificationsCount } = useSocketContext();
  const { authentication } = useAuthentication();
  const { route } = useRouter();

  const isLandingPage = ROUTES_LANDING_PAGES.includes(route);

  const isTeacher = authentication?.authData?.userType === UserType.Teacher;
  const { data: teacherProfileMilestonesStatus } = useQuery<
    TeacherApi['private']['getMilestonesStatus']['response']
  >([GET_MILESTONE_STATUS_URL], {
    enabled: (authentication?.isAuthenticated ?? false) && isTeacher,
  });

  const context = useMemo(
    () => ({
      isConnected: authentication?.isAuthenticated,
      photoUrl: authentication?.authData?.photoUrl,
      firstName: authentication?.authData?.firstName,
      lastName: authentication?.authData?.lastName,
      userType: authentication?.authData?.userType,
      isLandingPage,
      notificationsCount,
      route,
      teacherProfileCompletion:
        teacherProfileMilestonesStatus?.profileCompletionPercentage,
    }),
    [
      authentication,
      isLandingPage,
      notificationsCount,
      route,
      teacherProfileMilestonesStatus,
    ]
  );

  return (
    <NavbarContext.Provider value={context}>{children}</NavbarContext.Provider>
  );
};

export default NavbarContextProvider;
