import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Phone as PhoneIcon } from '@mui/icons-material';
import { Typography } from '@mui/material';

import formatPhoneNumber from '@/utils/formatPhoneNumber';

import messages from './messages';

import useStyles from './styles';

export type SalesPhoneNumberButtonProps = {
  variant: 'smallBlue' | 'bigBlack';
  label: 'callUs' | 'needHelp';
  phoneNumber: string;
  onClick: () => void;
};

const labelMessageVariant = {
  callUs: messages.labelCallUs,
  needHelp: messages.labelNeedHelp,
};

const PhoneNumberButton: FC<SalesPhoneNumberButtonProps> = ({
  variant,
  label,
  phoneNumber,
  onClick,
}) => {
  const { classes, cx } = useStyles();

  return (
    <button
      type="button"
      className={cx(classes.container, variant)}
      onClick={onClick}
    >
      <div className={cx(classes.iconContainer, variant)}>
        <PhoneIcon className={classes.icon} />
      </div>
      <div className={classes.rightSection}>
        <Typography
          variant={variant === 'smallBlue' ? 'bodySmBold' : 'bodyMdBold'}
          className={cx(classes.title, classes.lineHeight, variant)}
        >
          <FormattedMessage {...labelMessageVariant[label]} />
        </Typography>

        <Typography
          variant={variant === 'smallBlue' ? 'bodyMdBold' : 'bodyLgBold'}
          className={cx(classes.phoneNumber, classes.lineHeight, variant)}
        >
          {formatPhoneNumber(phoneNumber)}
        </Typography>
      </div>
    </button>
  );
};

export default PhoneNumberButton;
