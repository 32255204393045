import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/BaseSvg';

const IconCircle: FC<ComponentProps<typeof BaseSvg>> = ({ className = '' }) => (
  <BaseSvg className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.45455C6.38505 3.45455 3.45455 6.38505 3.45455 10C3.45455 13.615 6.38505 16.5455 10 16.5455C13.615 16.5455 16.5455 13.615 16.5455 10C16.5455 6.38505 13.615 3.45455 10 3.45455ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z"
    />
  </BaseSvg>
);

export default IconCircle;
