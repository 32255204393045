import { defineMessages } from 'react-intl';

export default defineMessages({
  button: {
    id: 'ds.onboarding.email.button',
    defaultMessage: `{variant, select, 
      login {Continuer avec un email}
      other {S'inscrire avec un email}
    }`,
  },
});
