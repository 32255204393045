import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/BaseSvg';

const IconVisa: FC<ComponentProps<typeof BaseSvg>> = ({ className = '' }) => (
  <BaseSvg className={className}>
    <path
      d="M18.3333 17C19.2538 17 20 16.2165 20 15.25V4.75001C20 3.7835 19.2538 3 18.3333 3L1.66666 3.00004C0.746189 3.00005 0 3.78355 0 4.75005V15.25C0 16.2165 0.746192 17 1.66667 17H18.3333Z"
      fill="white"
    />
    <path
      d="M19.75 15.25C19.75 16.0901 19.1044 16.75 18.3333 16.75H1.66667C0.895618 16.75 0.25 16.0901 0.25 15.25V4.75005C0.25 3.90999 0.895615 3.25005 1.66666 3.25004L18.3333 3.25C19.1044 3.25 19.75 3.90994 19.75 4.75001V15.25Z"
      stroke="black"
      strokeOpacity="0.2"
      strokeWidth="0.5"
    />
    <path
      d="M2.32303 8.17514C1.88708 7.92407 1.38953 7.72215 0.833252 7.58202L0.856586 7.4729H3.1374C3.44655 7.48428 3.69741 7.58194 3.77904 7.92687L4.27472 10.4083L4.42657 11.1557L5.81489 7.4729H7.31391L5.08565 12.8679H3.58656L2.32303 8.17514ZM8.41656 12.8736H6.99894L7.88562 7.4729H9.30317L8.41656 12.8736ZM13.5556 7.60493L13.3628 8.77114L13.2346 8.71379C12.978 8.6046 12.6394 8.49547 12.1786 8.507C11.6189 8.507 11.3679 8.74243 11.362 8.97228C11.362 9.22512 11.6657 9.39174 12.1615 9.63885C12.9783 10.0238 13.3571 10.4949 13.3515 11.1097C13.34 12.2301 12.3716 12.9541 10.8841 12.9541C10.2481 12.9483 9.63558 12.8159 9.30309 12.6666L9.50147 11.4543L9.68814 11.5406C10.149 11.7419 10.4523 11.8279 11.0182 11.8279C11.4264 11.8279 11.864 11.6611 11.8696 11.2993C11.8696 11.0637 11.6831 10.8912 11.1347 10.6269C10.5981 10.3683 9.88062 9.93743 9.89225 9.16174C9.8983 8.11037 10.8841 7.375 12.2841 7.375C12.8325 7.375 13.276 7.49565 13.5556 7.60493ZM15.4397 10.9603H16.618C16.5597 10.6903 16.2913 9.39755 16.2913 9.39755L16.1922 8.93219C16.1222 9.13326 15.9998 9.46078 16.0057 9.44924C16.0057 9.44924 15.5565 10.6501 15.4397 10.9603ZM17.1896 7.4729L18.3333 12.8735H17.0207C17.0207 12.8735 16.8922 12.253 16.8515 12.0634H15.0314C14.9788 12.2069 14.7339 12.8735 14.7339 12.8735H13.2464L15.3521 7.92099C15.498 7.57049 15.7549 7.4729 16.0931 7.4729H17.1896Z"
      fill="#171E6C"
    />
  </BaseSvg>
);

export default IconVisa;
