import { REDIRECT_URL_ITEM } from '@/data/navbar/navButtons';
import useLocalStorage from '@/hooks/useLocalStorage';

const useLoginRedirectionLocalStorage = () => {
  const {
    getItem: getFromLocalStorage,
    setItem: setToLocalStorage,
    removeItem: removeFromLocalStorage,
    popItem: popFromLocalStorage,
  } = useLocalStorage<string>(REDIRECT_URL_ITEM);

  const getItem = () => getFromLocalStorage();

  const saveItem = ({
    item = window.location.href,
    override = false,
  }: {
    item?: string;
    override?: boolean;
  }) => {
    if (override) {
      setToLocalStorage(item);
      return;
    }
    const hasItem = !!getItem();
    if (hasItem) {
      return;
    }
    setToLocalStorage(item);
  };

  const removeItem = () => removeFromLocalStorage();

  const popItem = () => popFromLocalStorage();

  return {
    getItem,
    saveItem,
    removeItem,
    popItem,
  };
};

export default useLoginRedirectionLocalStorage;
