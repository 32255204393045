import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelCallUs: {
    id: 'components.SalesPhoneNumber.labelCallUs',
    defaultMessage: 'Appelez-nous',
  },
  labelNeedHelp: {
    id: 'components.SalesPhoneNumber.labelNeedHelp',
    defaultMessage: "Une question, besoin d'aide ?",
  },
});

export default messages;
