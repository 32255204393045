import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import {
  getBackgroundColorFromVariant,
  getColorFromVariant,
} from '@/styles/getVariantColor';
import { Variant } from '@/types/Variant';

export default makeStyles<{ variant: Variant }>()((_theme, { variant }) => ({
  root: {
    display: 'flex',
    borderRadius: rem(8),
    padding: rem(8),
    gap: rem(6),
    alignItems: 'center',
    ...getBackgroundColorFromVariant(variant),
  },
  text: {
    ...getColorFromVariant(variant),
  },
}));
