import {
  FC,
  HTMLAttributes,
  MouseEventHandler,
  PropsWithChildren,
} from 'react';
import { FocusOn } from 'react-focus-on';

import ModalBody, { BodyCompound } from '@/Organisms/Modal/ModalBody/ModalBody';
import ModalFooter, {
  ModalFooterCompound,
} from '@/Organisms/Modal/ModalFooter/ModalFooter';
import ModalHeader, {
  ModalHeaderCompound,
} from '@/Organisms/Modal/ModalHeader/ModalHeader';

import useStyles from './styles';

export type ModalType = {
  isOpen: boolean;
  isFocusLocked?: boolean;
  onClose: () => void;
  hasCustomWidth?: boolean;
  isScrollable?: boolean;
};

type ModalCompound = {
  Header: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> &
    ModalHeaderCompound;
  Body: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> & BodyCompound;
  Footer: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> &
    ModalFooterCompound;
};

const Modal: FC<PropsWithChildren<ModalType & HTMLAttributes<HTMLDivElement>>> &
  ModalCompound = ({
  isOpen,
  isFocusLocked = true,
  onClose,
  className,
  children,
  hasCustomWidth = false,
  isScrollable = false,
}) => {
  const { classes, cx } = useStyles({ isScrollable });

  const handleModalClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {isOpen && (
        <div
          className={classes.backdrop}
          onClick={handleModalClick}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
        >
          <FocusOn
            onClickOutside={onClose}
            onEscapeKey={onClose}
            className={classes.focuslock}
            focusLock={isFocusLocked}
          >
            <section
              className={cx(
                classes.root,
                {
                  [classes.modalSize]: !hasCustomWidth,
                },
                className
              )}
            >
              {children}
            </section>
          </FocusOn>
        </div>
      )}
    </>
  );
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
