import { FC, PropsWithChildren } from 'react';

import Link, { LinkProps } from './Link/Link';

import useStyles from './styles';

type Compound = {
  Link: FC<PropsWithChildren<LinkProps>>;
};

const Dropdown: FC<PropsWithChildren<unknown>> & Compound = ({ children }) => {
  const { classes } = useStyles();

  return <div className={classes.root}>{children}</div>;
};

Dropdown.Link = Link;

export default Dropdown;
