import { FC, HTMLAttributes, ReactEventHandler } from 'react';
import { useIntl } from 'react-intl';

import NextLinkWithNoCss from '@/components/NextLinkWithNoCss/NextLinkWithNoCss';
import Button, { ButtonProps } from '@/designSystem/Button/Button';
import commonMessages from '@/utils/common.messages';

import useStyles from './styles';

export type ModalFooterCompound = {
  Button: FC<ButtonProps & ModalFooterButtonProps>;
};

type ModalFooterButtonProps = {
  redirectTo?: string;
  handleButtonClick?: ReactEventHandler;
  label?: string;
};

const ButtonModalFooter: FC<ModalFooterButtonProps> = ({
  redirectTo,
  handleButtonClick,
  label,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  return redirectTo ? (
    <NextLinkWithNoCss href={redirectTo}>
      <Button
        {...rest}
        onClick={handleButtonClick}
        label={label ?? formatMessage(commonMessages.next2)}
      />
    </NextLinkWithNoCss>
  ) : (
    <Button
      {...rest}
      onClick={handleButtonClick}
      label={label ?? formatMessage(commonMessages.next2)}
    />
  );
};

const ModalFooter: FC<HTMLAttributes<HTMLDivElement>> & ModalFooterCompound = ({
  children,
  className,
}) => {
  const { classes, cx } = useStyles();
  return <div className={cx(classes.ModalFooter, className)}>{children}</div>;
};

ModalFooter.Button = ButtonModalFooter;

export default ModalFooter;
