import { FC, PropsWithChildren } from 'react';

const Tiktok: FC<PropsWithChildren<unknown>> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.89323 9.87637V9.09703C9.62269 9.05416 9.34943 9.03068 9.07555 9.02675C6.42399 9.02115 4.07645 10.7394 3.28022 13.2686C2.484 15.7978 3.42385 18.551 5.60044 20.0654C3.97002 18.3204 3.51731 15.7803 4.44452 13.5795C5.37173 11.3788 7.5057 9.92847 9.89323 9.87637Z"
      fill="#25F4EE"
    />
    <path
      d="M10.0403 18.7048C11.5228 18.7028 12.7414 17.5349 12.8063 16.0538V2.83684H15.221C15.1717 2.56067 15.1482 2.28052 15.1507 2H11.8481V15.2042C11.7932 16.6928 10.5717 17.872 9.08206 17.8744C8.6369 17.8706 8.19901 17.7611 7.80444 17.555C8.3231 18.2736 9.15405 18.7009 10.0403 18.7048Z"
      fill="#25F4EE"
    />
    <path
      d="M19.7309 7.32131V6.58668C18.8422 6.58698 17.9733 6.32474 17.2332 5.83289C17.882 6.58714 18.7588 7.10961 19.7309 7.32131Z"
      fill="#25F4EE"
    />
    <path
      d="M17.2334 5.83284C16.5044 5.0026 16.1024 3.93536 16.1027 2.83044H15.2212C15.4513 4.06506 16.1789 5.15074 17.2334 5.83284Z"
      fill="#FE2C55"
    />
    <path
      d="M9.07536 12.3295C7.80314 12.3361 6.69864 13.2076 6.39648 14.4435C6.09432 15.6793 6.67206 16.9622 7.79774 17.555C7.1878 16.7129 7.10164 15.5999 7.57471 14.6739C8.04779 13.748 9.00017 13.1656 10.04 13.1664C10.3172 13.1698 10.5925 13.2128 10.8576 13.2941V9.934C10.5869 9.89344 10.3137 9.87209 10.04 9.87012H9.89303V12.4253C9.62657 12.3539 9.35112 12.3216 9.07536 12.3295Z"
      fill="#FE2C55"
    />
    <path
      d="M19.731 7.32129V9.87652C18.0859 9.87333 16.4835 9.35261 15.1508 8.3881V15.102C15.1437 18.4521 12.4259 21.1643 9.0757 21.1643C7.83153 21.1665 6.61734 20.7826 5.60059 20.0655C7.2974 21.8905 9.93813 22.4912 12.2574 21.5798C14.5767 20.6685 16.1021 18.4307 16.1026 15.9388V9.2441C17.4398 10.2023 19.0442 10.7162 20.6892 10.7134V7.4235C20.3672 7.42248 20.0461 7.38822 19.731 7.32129Z"
      fill="#FE2C55"
    />
    <path
      d="M15.1508 15.1019V8.38807C16.4876 9.34704 18.0923 9.86107 19.7375 9.85733V7.3021C18.7655 7.09686 17.8866 6.58119 17.2334 5.83284C16.1789 5.15074 15.4512 4.06506 15.2211 2.83044H12.8064V16.0538C12.7581 17.2335 11.9679 18.2532 10.8376 18.5945C9.70728 18.9357 8.48484 18.5236 7.79177 17.5677C6.66609 16.9749 6.08835 15.6921 6.39051 14.4562C6.69267 13.2204 7.79717 12.3488 9.06939 12.3423C9.34672 12.3448 9.62219 12.3878 9.88706 12.4701V9.91482C7.48615 9.9555 5.33605 11.4109 4.40621 13.6248C3.47636 15.8387 3.94243 18.3929 5.59427 20.1357C6.62105 20.8292 7.83701 21.1884 9.07578 21.1642C12.426 21.1642 15.1438 18.4521 15.1508 15.1019Z"
      fill="black"
    />
  </svg>
);

export default Tiktok;
