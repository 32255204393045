import { defineMessages } from 'react-intl';

export default defineMessages({
  parameters: {
    id: 'user.parameters',
    defaultMessage: 'Paramètres',
  },
  helpCenter: {
    id: 'user.help.center',
    defaultMessage: 'Centre d’aide',
  },
  logout: {
    id: 'user.logout',
    defaultMessage: 'Déconnexion',
  },
});
