import { FC } from 'react';

import { PRIVATE_COURSE } from '@/data/navbar/navButtons';
import { useNavbarContext } from '@/shared/Navbar/context/NavbarContext';
import NavbarAuthUser from '@/shared/Navbar/Variants/NavbarAuthUser/NavbarAuthUser';
import NavbarGuestUser from '@/shared/Navbar/Variants/NavbarGuestUser/NavbarGuestUser';
import NavbarSeo from '@/shared/Navbar/Variants/NavbarSeo/NavbarSeo';

const SEO_PAGES = [
  PRIVATE_COURSE,
  `${PRIVATE_COURSE}/[seo]`,
  '/cours/[subject]',
  '/cours/[subject]/[city]',
  '/cours/[subject]/online',
  '/cours/toute-matiere/[city]',
  '/cours/toute-matiere/france/[level]',
];

const NavBarVariant: FC = () => {
  const { isConnected, route } = useNavbarContext();

  const shouldDisplayAuthUserNavbar = isConnected;
  if (shouldDisplayAuthUserNavbar) {
    return <NavbarAuthUser />;
  }

  const shouldDisplaySeoNavbar = SEO_PAGES.includes(route);
  if (shouldDisplaySeoNavbar) {
    return <NavbarSeo />;
  }

  return <NavbarGuestUser />;
};

export default NavBarVariant;
