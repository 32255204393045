import { FC, HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import { Typography } from '@mui/material';

import themeFoundation from '@/designSystem/theme';

import useStyles from './styles';

type Props = {
  identifier?: string | ReactNode;
  text: string | ReactNode;
  color?: string;
};

const Helper: FC<PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>> = ({
  identifier = '',
  text,
  color = themeFoundation.palette.neutral[500],
  className = '',
}) => {
  const { classes } = useStyles({ color });

  return (
    <div className={`${classes.root} ${className}`}>
      {identifier && (
        <Typography
          className={`${classes.textColor} ${classes.onOneLine} `}
          variant="bodySmBold"
          component="span"
        >
          {identifier}
        </Typography>
      )}
      <Typography
        className={classes.textColor}
        variant="bodySm"
        component="span"
      >
        {text}
      </Typography>
    </div>
  );
};

export default Helper;
