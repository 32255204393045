import { FC, PropsWithChildren, ReactNode, useState } from 'react';

import { ToastProvider as ToastContextProvider } from '@/shared/Toast/Toast.context';

const ToastProvider: FC<PropsWithChildren> = ({ children }) => {
  const [toast, setToast] = useState<ReactNode | null>(null);

  const handleClose = () => {
    setToast(null);
  };

  return (
    <ToastContextProvider
      value={{
        addToast: setToast,
        onClose: handleClose,
      }}
    >
      {toast}
      {children}
    </ToastContextProvider>
  );
};

export default ToastProvider;
