import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles<{ isPositionStart: boolean }>()(
  (theme, { isPositionStart }) => ({
    root: {
      display: 'flex',
      padding: rem(12),
      border: `1px solid ${theme.palette.neutral[700]}`,
      order: isPositionStart ? 0 : 2,
      borderLeft: isPositionStart && '0',
      borderRight: !isPositionStart && '0',
      background: theme.palette.neutral.light,
    },
  })
);
