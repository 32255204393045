import { ComponentProps, FC, PropsWithChildren } from 'react';

type Props = Pick<ComponentProps<'svg'>, 'className'>;

const BaseSvg: FC<PropsWithChildren<Props>> = ({ className, children }) => (
  <svg
    className={className}
    viewBox="0 0 20 20"
    fill="currentColor"
    style={{ flexShrink: 0 }}
    focusable="false"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
  >
    {children}
  </svg>
);

export default BaseSvg;
