import { FC } from 'react';

import { HOME } from '@/data/navbar/navButtons';
import { HEADER_NAVIGATION_ID } from '@/shared/Navbar/constant';
import { useNavbarContext } from '@/shared/Navbar/context/NavbarContext';
import LinkSherpa from '@/shared/Navbar/Items/LinkSherpa/LinkSherpa';
import SignupButton from '@/shared/Navbar/Items/SignupButton/SignupButton';
import useCommonStyles from '@/shared/Navbar/styles';

const NavbarSeoDesktop: FC = () => {
  const { isLandingPage } = useNavbarContext();
  const { classes: commonClasses } = useCommonStyles({
    shouldAnimateNavBar: isLandingPage,
  });

  return (
    <header className={commonClasses.root} id={HEADER_NAVIGATION_ID}>
      <div className={commonClasses.navContainer}>
        <div className={commonClasses.navigationMenu}>
          <LinkSherpa href={HOME} />
        </div>

        <div className={commonClasses.itemNavigation}>
          <div className={commonClasses.connexionButton}>
            <SignupButton />
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavbarSeoDesktop;
