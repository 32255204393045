import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/BaseSvg';

const IconGoogle: FC<ComponentProps<typeof BaseSvg>> = ({ className = '' }) => (
  <BaseSvg className={className}>
    <path
      d="M18.1564 10.1875C18.1564 9.63892 18.1078 9.11809 18.0245 8.61115H10.1772V11.7431H14.6703C14.4689 12.7709 13.8786 13.6389 13.0036 14.2292V16.3125H15.6842C17.2536 14.8611 18.1564 12.7223 18.1564 10.1875Z"
      fill="#4285F4"
    />
    <path
      d="M10.1772 18.3333C12.4272 18.3333 14.3092 17.5833 15.6842 16.3125L13.0036 14.2292C12.2536 14.7292 11.3022 15.0347 10.1772 15.0347C8.00364 15.0347 6.16336 13.5694 5.50364 11.5903H2.73975V13.7361C4.1078 16.4583 6.9203 18.3333 10.1772 18.3333Z"
      fill="#34A853"
    />
    <path
      d="M5.50347 11.5903C5.32986 11.0903 5.23958 10.5556 5.23958 10C5.23958 9.44447 5.33681 8.90975 5.50347 8.40975V6.26392H2.73958C2.17014 7.38892 1.84375 8.6528 1.84375 10C1.84375 11.3472 2.17014 12.6111 2.73958 13.7361L5.50347 11.5903Z"
      fill="#FBBC05"
    />
    <path
      d="M10.1772 4.9653C11.4064 4.9653 12.5036 5.38891 13.3717 6.2153L15.7467 3.8403C14.3092 2.49308 12.4272 1.66669 10.1772 1.66669C6.9203 1.66669 4.1078 3.54169 2.73975 6.26391L5.50364 8.40974C6.16336 6.43058 8.00364 4.9653 10.1772 4.9653Z"
      fill="#EA4335"
    />
  </BaseSvg>
);

export default IconGoogle;
