import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  description: {
    marginBottom: rem(4),
  },
  title: {
    marginBottom: rem(32),
  },
  avatar: {
    marginTop: rem(32),
    marginBottom: rem(10),
  },
}));
