import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';

import VariantIcon from '@/Molecules/VariantIcon/VariantIcon';
import { Variant } from '@/types/Variant';

import useStyles from './styles';

type Props = {
  variant?: Variant;
  isWithOutIcon?: boolean;
};

const Alerts: FC<PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>> = ({
  variant = 'Information',
  isWithOutIcon = false,
  children,
  className,
  ...rest
}) => {
  const { classes } = useStyles({ variant });

  return (
    <div className={`${classes.root} ${className}`} {...rest}>
      {!isWithOutIcon && <VariantIcon variant={variant} />}
      <Typography
        variant="bodyMdBold"
        component="span"
        className={classes.text}
      >
        {children}
      </Typography>
    </div>
  );
};
export default Alerts;
