import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles<{ color: string }>()((_theme, { color }) => ({
  root: {
    display: 'flex',
    gap: rem(4),
  },
  textColor: {
    color,
  },
  onOneLine: {
    whiteSpace: 'nowrap',
  },
}));
