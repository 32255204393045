import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

type Props = {
  isReadOnly: boolean;
};

export default makeStyles<Props>()(
  ({ palette, shadow, breakpoints }, { isReadOnly }) => ({
    root: {
      width: '100%',
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
    },
    input: {
      width: '100%',
      height: '100%',
      padding: `${rem(8)} ${rem(1)}`,
      fontSize: rem(16),
      fontWeight: 'bold',
      lineHeight: rem(24),
      cursor: isReadOnly && 'not-allowed',
    },
    placeholder: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      /** FOR FIXING ISSUE WITH FOCUS FOR IPHONE
       * https://webdevdesigner.com/q/disable-auto-zoom-in-input-text-tag-safari-on-iphone-70615/
       * important required here because can't surcharge font-size of component input otherwise
       */
      fontSize: `${rem(16)} !important`,
      '&::placeholder': {
        opacity: 1,
        color: palette.neutral[600],
        fontWeight: 'bold',
        lineHeight: rem(24),
      },
    },
    iconButton: {
      color: palette.primary[400],
      padding: rem(14),
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    closeButton: {
      padding: 0,
      marginRight: rem(10),
      color: palette.neutral[600],
      [breakpoints.up('md')]: {
        display: 'none',
      },
    },
    options: {
      zIndex: 100,
      position: 'absolute',
      top: rem(50),
      left: rem(44),
      width: '80%',
      [breakpoints.up('md')]: {
        top: rem(64),
      },
      boxShadow: shadow.high,
    },
  })
);
