import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/BaseSvg';

const IconLightningBolt: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3006 1.04627C11.7169 1.17749 12 1.56354 12 2.00001V7.00001L16 7.00001C16.3729 7.00001 16.7148 7.20747 16.887 7.5382C17.0592 7.86893 17.0331 8.268 16.8192 8.57347L9.81924 18.5735C9.56894 18.931 9.11564 19.085 8.69936 18.9537C8.28309 18.8225 8 18.4365 8 18L8 13H4C3.62713 13 3.28522 12.7926 3.11302 12.4618C2.94083 12.1311 2.96694 11.732 3.18077 11.4265L10.1808 1.42655C10.4311 1.06898 10.8844 0.915053 11.3006 1.04627Z"
    />
  </BaseSvg>
);
export default IconLightningBolt;
