import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import noCssButton from '@/styles/noCssButton';
import noCssLinkTag from '@/styles/noCssTag';

export default makeStyles()(({ palette, breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  link: {
    ...noCssLinkTag,
    ...noCssButton,
    minWidth: 'unset',
    padding: `${rem(4)} ${rem(12)}`,
    fontWeight: 500,
    borderRadius: rem(8),
    [breakpoints.down('lg')]: {
      justifyContent: 'flex-start',
    },
    '&:hover': {
      backgroundColor: palette.neutral[900],
    },
  },
}));
