import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, shadow, border }) => ({
  root: {
    position: 'absolute',
    backgroundColor: palette.common.white,
    display: 'flex',
    flexFlow: 'column',
    padding: rem(16),
    borderRadius: rem(12),
    top: rem(52),
    right: 0,
    minWidth: rem(180),
    gap: rem(12),
    boxShadow: shadow.high,
    border: border.main,
  },
}));
