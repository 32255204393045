import themeFoundation from '@/designSystem/theme';
import { Variant } from '@/types/Variant';

export const getBackgroundColorFromVariant = (variant: Variant) => {
  switch (variant) {
    case 'Error':
      return {
        backgroundColor: themeFoundation.palette.supporting.red[900],
      };
    case 'Information':
      return {
        backgroundColor: themeFoundation.palette.supporting.blue[900],
      };
    case 'Confirmation':
      return {
        backgroundColor: themeFoundation.palette.primary[900],
      };
    case 'Warning':
      return {
        backgroundColor: themeFoundation.palette.supporting.amber[900],
      };
    default: {
      return {
        backgroundColor: themeFoundation.palette.supporting.amber[900],
      };
    }
  }
};

export const getColorFromVariant = (variant: Variant) => {
  switch (variant) {
    case 'Error':
      return {
        color: themeFoundation.palette.supporting.red[200],
      };
    case 'Information':
      return {
        color: themeFoundation.palette.supporting.blue[200],
      };
    case 'Confirmation':
      return {
        color: themeFoundation.palette.primary[200],
      };
    case 'Warning':
      return {
        color: themeFoundation.palette.supporting.amber[200],
      };
    default: {
      return {
        color: themeFoundation.palette.supporting.amber[200],
      };
    }
  }
};
