import {
  HAS_OTHER_PROVIDER_AUTH_ERROR,
  ResetEmailUserDto,
  SendEmailActionDto,
  UserType,
} from '@les-sherpas/sherpas-toolbox';
import axios from 'axios';
import server from 'config';

import { Level } from '@/types/level';
import { Subject } from '@/types/subject';

export type LoginReturnType = {
  success: boolean;
  isOtherProviderError: boolean;
  user?: {
    id: number;
    userType: UserType;
  };
};

export const login = async (user: {
  username: string;
  password: string;
}): Promise<LoginReturnType> => {
  try {
    const { data, status } = await axios.post(`${server}auth/login`, user, {
      withCredentials: true,
      validateStatus: () => true,
    });

    return {
      success: status === 201,
      isOtherProviderError: data.message === HAS_OTHER_PROVIDER_AUTH_ERROR,
      user: data,
    };
  } catch (e) {
    return {
      success: false,
      isOtherProviderError: false,
    };
  }
};

export const reSendEmailConfirmation = async (body: SendEmailActionDto) => {
  try {
    const response = await axios.post(
      `${server}auth/send-email-verification-code`,
      body,
      {
        withCredentials: true,
      }
    );
    return response.status === 200 || response.status === 201;
  } catch (e) {
    return false;
  }
};

export const resetEmail = async (body: ResetEmailUserDto) => {
  try {
    const { status, data } = await axios.post(
      `${server}auth/reset-email`,
      body,
      {
        withCredentials: true,
      }
    );
    return (status === 200 || status === 201) && data;
  } catch (e) {
    return false;
  }
};

export const getAllSubjects = async () => {
  try {
    const { data } = await axios.get<Subject[]>(`${server}subject/`, {
      withCredentials: true,
    });
    return data;
  } catch (e) {
    return [];
  }
};

export const getAllLevels = async () => {
  try {
    const { data } = await axios.get<Level[]>(`${server}levels/`, {
      withCredentials: true,
    });
    return data;
  } catch (e) {
    return [];
  }
};

export const getRelatedSubjects = async (code: string) => {
  try {
    const { data } = await axios.get<Subject[]>(
      `${server}subject/findByParentOrTag/${code}`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    return [];
  }
};

export const getSignupTagName = (userType: UserType) =>
  userType === UserType.Student ? 'student_signup_completed' : 'signup_teacher';
