import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { UserType } from '@les-sherpas/sherpas-toolbox';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import { animated, useSpring } from '@react-spring/web';
import { useQuery } from '@tanstack/react-query';

import Input from '@/Organisms/SearchBar/Input/Input';
import messages from '@/Organisms/SearchBar/messages';
import { orderSubjects } from '@/Organisms/SearchBar/utils';
import { defaultSearchSubjects } from '@/shared/defaultSearchSubjects';
import useNavigation from '@/shared/Hooks/useNavigation';
import { useNavbarContext } from '@/shared/Navbar/context/NavbarContext';
import { Subject } from '@/types/subject';
import { useIsMobile } from '@/utils/useResponsive';

import useStyles from './styles';

const NavbarSearch: FC = () => {
  const { userType, route } = useNavbarContext();
  const isMobile = useIsMobile();
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const { pushTo, urls } = useNavigation();

  // On masque la recherche sur les pages contenant une recherche
  const ROUTES_WITHOUT_SEARCH = [
    urls.home(),
    urls.search(),
    '/oldCours/[...seo]',
    urls.homeMaman(),
    '/cours/[subject]',
    '/cours/[subject]/[city]',
    '/cours/[subject]/online',
    '/cours/toute-matiere/[city]',
    '/cours/toute-matiere/france/[level]',
  ];

  const shouldShowSearch =
    userType !== UserType.Teacher && !ROUTES_WITHOUT_SEARCH.includes(route);
  const { data: subjects = [] } = useQuery<Subject[]>(['subject'], {
    enabled: shouldShowSearch,
  });

  const [subject, setSubject] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const onClose = () => {
    setSubject('');
    setIsSearchOpen(false);
  };

  const goToSearch = async (subject: string) => {
    pushTo(urls.search({ subject }));
  };

  const slide = useSpring({
    config: { duration: 300 },
    transform: isSearchOpen ? 'translateY(0%)' : 'translateY(-100%)',
  });

  if (!shouldShowSearch) {
    return null;
  }

  return (
    <>
      {isMobile ? (
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={() => setIsSearchOpen(true)}
          size="large"
        >
          <SearchIcon />
        </IconButton>
      ) : null}

      <animated.div
        style={isMobile ? slide : undefined}
        className={isMobile ? classes.searchPanel : undefined}
      >
        <Input
          preSelectedData={defaultSearchSubjects}
          Icon={SearchIcon}
          placeholder={formatMessage(messages.searchPlaceholder)}
          ariaLabel={formatMessage(messages.searchAriaLabel)}
          onOptionChange={orderSubjects}
          datas={subjects}
          inputValue={subject}
          setInputValue={setSubject}
          onTextChange={goToSearch}
          cssRoot={classes.root}
          cssInput={classes.input}
          cssIcon={classes.iconButton}
          cssPlaceholder={classes.placeholder}
          onIconButtonClick={goToSearch}
          onEnter={() => goToSearch(subject)}
          onClose={onClose}
        />
      </animated.div>
    </>
  );
};

export default NavbarSearch;
