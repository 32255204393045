import {
  ChangeEvent,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { UserType } from '@les-sherpas/sherpas-toolbox';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { IconButton, Typography } from '@mui/material';
import { useRouter } from 'next/router';

import {
  OnboardingStudentDrawerSettings,
  Origin,
  RegisterMode,
} from '@/components/OnBoardingStudent/common/OnboardingStudent.type';
import { ONBOARDING_STUDENT_DRAWER_SETTINGS } from '@/data/navbar/navButtons';
import useLocalStorage from '@/hooks/useLocalStorage';
import Alerts from '@/Molecules/Alerts/Alerts';
import LoginEmailModalBody from '@/Organisms/LoginSignUpModal/LoginEmailModalBody/LoginEmailModalBody';
import { LoginSignUpVariant } from '@/Organisms/LoginSignUpModal/LoginSignUp.type';
import LoginSignUpModalBody from '@/Organisms/LoginSignUpModal/LoginSignUpModalBody/LoginSignUpModalBody';
import LoginSignUpModalFooter from '@/Organisms/LoginSignUpModal/LoginSignUpModalFooter/LoginSignUpModalFooter';
import LoginSignUpModalTeacher, {
  LoginSignUpModalTeacherInfosProps,
} from '@/Organisms/LoginSignUpModal/LoginSignUpModalTeacherInfos/LoginSignUpModalTeacherInfos';
import Modal, { ModalType } from '@/Organisms/Modal/Modal';
import useNavigation from '@/shared/Hooks/useNavigation';
import { Provider } from '@/types/provider';

import messages from './messages';

import useStyles from './styles';

export type LoginSignUpModalProps = {
  origin: Origin;
  initialVariant?: LoginSignUpVariant;
  drawerExitUrl: string;
  teacher?: LoginSignUpModalTeacherInfosProps;
  userType?: UserType;
  hasLoginError?: boolean;
  setHasLoginError?: Dispatch<SetStateAction<boolean>>;
};

const LoginSignUpModal: FC<
  PropsWithChildren<Partial<ModalType> & LoginSignUpModalProps>
> = ({
  drawerExitUrl,
  hasLoginError = false,
  initialVariant = 'login',
  isOpen,
  onClose,
  origin,
  teacher,
  setHasLoginError = () => {},
  /** normally we don't have a userType */
  userType,
}) => {
  const [shouldDisplayLoginForm, setShouldDisplayLoginForm] = useState(false);
  const [variant, setVariant] = useState<LoginSignUpVariant>(initialVariant);
  const { setItem } = useLocalStorage<OnboardingStudentDrawerSettings>(
    ONBOARDING_STUDENT_DRAWER_SETTINGS
  );
  const { push, asPath, replace } = useRouter();
  const { pushTo, urls } = useNavigation();

  const { classes } = useStyles({
    hasTeacher: !!teacher,
    shouldDisplayLoginForm,
  });

  const saveOriginAndRegisterModeInLocalStorage = ({
    registerMode,
  }: {
    registerMode: RegisterMode;
  }) => {
    setItem({
      registerMode,
      origin,
      originUrl: asPath,
      drawerExitUrl,
    });
  };

  const handleEmailSignup = (_e: ChangeEvent<HTMLElement>) => {
    if (variant === 'login') {
      setShouldDisplayLoginForm(true);
    } else {
      saveOriginAndRegisterModeInLocalStorage({ registerMode: 'email' });
      pushTo(urls.drawerSignUp());
    }
  };

  const handleGafamButtonClick = (
    _e: ChangeEvent<HTMLElement>,
    provider: Provider
  ) => {
    saveOriginAndRegisterModeInLocalStorage({
      registerMode: provider,
    });
  };

  const handleBackButtonClick = () => {
    setShouldDisplayLoginForm(false);
  };

  const toggleVariant = () => {
    setHasLoginError(false);
    setVariant(variant === 'login' ? 'signup' : 'login');
  };

  const handleOnClose = () => {
    const url = new URL(window.location.href.split('#')[0]);
    url.searchParams.delete('loginModalError');
    url.searchParams.delete('loginModal');
    url.searchParams.delete('fromBlog');
    url.searchParams.delete('fromUnknown');
    replace(url, undefined, {
      scroll: false,
      shallow: true,
    });
    onClose();
  };

  const handleEmailLogin = () => {
    const redirectUrl = `/?method=login&redirectUrl=${encodeURIComponent(
      drawerExitUrl
    )}`;
    push(redirectUrl);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <div className={classes.container}>
        <Modal.Header className={classes.header}>
          <Modal.Header.TitleWithCloseIcon
            onClose={handleOnClose}
            shouldDisplayLoginForm={shouldDisplayLoginForm}
          >
            {shouldDisplayLoginForm && (
              <IconButton
                onClick={handleBackButtonClick}
                size="medium"
                className={classes.arrowBack}
              >
                <ArrowBack />
              </IconButton>
            )}
            <div className={classes.titleContainer}>
              <Typography variant="titleMd">
                <FormattedMessage {...messages.title} values={{ variant }} />
              </Typography>
              {!!teacher && (
                <Typography component="p">
                  <FormattedMessage
                    {...messages.subTitle}
                    values={{
                      firstName: teacher.firstName,
                    }}
                  />
                </Typography>
              )}
            </div>
          </Modal.Header.TitleWithCloseIcon>
        </Modal.Header>
        {hasLoginError && (
          <Alerts variant="Error" className={classes.error}>
            <FormattedMessage {...messages.errorConnection} />
          </Alerts>
        )}
        {!!teacher && !shouldDisplayLoginForm && (
          <LoginSignUpModalTeacher
            {...teacher}
            className={classes.teacherModal}
          />
        )}
        {shouldDisplayLoginForm ? (
          <LoginEmailModalBody
            closeModal={onClose}
            handleRedirection={handleEmailLogin}
          />
        ) : (
          <LoginSignUpModalBody
            userType={userType}
            variant={variant}
            onEmailButtonClick={handleEmailSignup}
            onGafamButtonClick={handleGafamButtonClick}
          />
        )}
        {!shouldDisplayLoginForm && (
          <LoginSignUpModalFooter variant={variant} onClick={toggleVariant} />
        )}
      </div>
    </Modal>
  );
};

export default LoginSignUpModal;
