import { defineMessages } from 'react-intl';
import { UserType } from '@les-sherpas/sherpas-toolbox';

export default defineMessages({
  accept: {
    id: 'common.button.accept',
    defaultMessage: 'Accepter',
  },
  advice: {
    id: 'common.helper.advice',
    defaultMessage: 'Conseil : ',
  },
  afterTaxReduction: {
    id: 'common.afterTaxReduction',
    defaultMessage: "Après crédit d'impôt",
  },
  askCourse: {
    id: 'common.askCourse',
    defaultMessage: `{userType,select,
      ${UserType.Teacher} {Proposer un cours}
      other {Demander un cours} 
    }`,
  },
  atHome: {
    id: 'teachers.page.teacher.location.atHome',
    defaultMessage: 'Se déplace',
  },
  back: {
    id: 'common.button.back',
    defaultMessage: 'Précédent',
  },
  cancel: {
    id: 'common.button.cancel',
    defaultMessage: 'Annuler',
  },
  cancelAction: {
    id: 'common.cancel.action.button',
    defaultMessage: `{isTeacher, select, 
      true { Annuler la proposition }
      other { Annuler la demande }
    }`,
  },
  cancelationHoliday: {
    id: 'common.cancelation.holiday',
    defaultMessage: `Une erreur s'est produite lors de l'annulation de vos vacances veuillez réessayer ultérieurement.`,
  },
  confirm: {
    id: 'common.button.confirm',
    defaultMessage: 'Confirmer',
  },
  confirmAndPay: {
    id: 'common.button.confirmAndPay',
    defaultMessage: 'Confirmer et payer',
  },
  confirmPasswordLabel: {
    id: 'common.confirm.password.label',
    defaultMessage: `{ isTeacher, select, 
      true {Confirme ton mot de passe 🔐}
      other {Confirmez votre mot de passe 🔐}
    }`,
  },
  close: {
    id: 'common.close.button',
    defaultMessage: 'Fermer',
  },
  create: {
    id: 'common.button.create',
    defaultMessage: 'Créer',
  },
  customOption: {
    id: 'common.custom.option',
    defaultMessage: 'Sur mesure',
  },
  delete: {
    id: 'common.button.delete',
    defaultMessage: 'Supprimer',
  },
  done: {
    id: 'common.done.button',
    defaultMessage: 'Effectué',
  },
  durationFirstCourse: {
    id: 'checkout.dmr.recount.duration',
    defaultMessage: 'Entre 30 min et 1 heure',
  },
  emailValidatorError: {
    id: 'common.email.validator.error',
    defaultMessage: `Le champ renseigné ne correspond pas à une adresse email valide`,
  },
  error: {
    id: 'common.helper.error',
    defaultMessage: 'Erreur :',
  },
  exit: {
    id: 'common.button.exit',
    defaultMessage: 'Quitter',
  },
  exitWithSave: {
    id: 'common.button.exitWithSave',
    defaultMessage: 'Enregistrer et Quitter',
  },
  finish: {
    id: 'common.button.finish',
    defaultMessage: 'Terminer',
  },
  flexibleSubtitle: {
    id: 'common.common.subscription.proposal.flexible.subtitle',
    defaultMessage:
      'Un imprévu ? Des vacances ? Les heures sont reportables : utilisez-les à votre rythme.',
  },
  flexibleTitle: {
    id: 'common.subscription.proposal.flexible.title',
    defaultMessage: '100% flexible',
  },
  futureDate: {
    id: 'common.futur.date',
    defaultMessage: 'Vous devez renseigner une date dans le futur',
  },
  globalError: {
    id: 'common.global.error',
    defaultMessage: `Une erreur est survenue, veuillez réessayer d'ici quelques instants`,
  },
  hasNoSpecialCharExceptTiret: {
    id: 'common.has.no.special.character.except.tiret.validator.error',
    defaultMessage: `Les caractères spéciaux, excepté “-” ne sont pas acceptés`,
  },
  incomeSubtitle: {
    id: 'common.common.subscription.proposal.income.subtitle',
    defaultMessage: `{isTeacher,select,
        true {Profite d’un revenu récurrent dès que tu es régulier dans ton rythme de cours.}
        other {Le Sherpa se charge de sa matière, on s’occupe des méthodes et de la motivation.}
        }`,
  },
  incomeTitle: {
    id: 'common.subscription.proposal.income.title',
    defaultMessage: `{isTeacher,select,
      true {Un revenu récurrent}
      other {Des ressources exclusives}
    }`,
  },
  isNotStartingWithTiret: {
    id: 'common.is.not.starting.with.tiret.or.space.validator.error',
    defaultMessage: `Veuillez commencer par une lettre`,
  },
  isValidUserNameValidatorError: {
    id: 'common.isValidUserName.validator.error',
    defaultMessage:
      'Le champ doit contenir au minimum 2 caractères et ne pas contenir de nombres ou de caractères spéciaux',
  },
  link: {
    id: 'common.link',
    defaultMessage: 'Voir',
  },
  location: {
    id: 'common.location.title',
    defaultMessage: `{location, select,
      online {En ligne}
      other {À domicile}
    }`,
  },
  locationError: {
    id: 'common.address.error',
    defaultMessage: `Veuillez renseigner une adresse valide`,
  },
  minimumLengthValidatorError: {
    id: 'common.minimum.length.validator.error',
    defaultMessage: `Vous devez renseigner un minimum de deux caractères`,
  },
  modify: {
    id: 'common.button.modify',
    defaultMessage: 'Modifier',
  },
  moneySubtitle: {
    id: 'common.common.subscription.proposal.money.subtitle',
    defaultMessage: `{isTeacher,select,
      true {Sur Les Sherpas, tu es payé au prix que tu demandes et tu as la garantie d’être payé après les cours.}
      other {Notre plateforme est conçue pour vous faciliter la vie. Notre équipe vous répond sous 24h.}
    }`,
  },
  moneyTitle: {
    id: 'common.subscription.proposal.money.title',
    defaultMessage: `{isTeacher,select,
      true {Ton taux horaire garanti}
      other {La tranquillité Sherpa}
    }`,
  },
  next: {
    id: 'common.button.next',
    defaultMessage: 'Suivant',
  },
  next2: {
    id: 'common.button.next',
    defaultMessage: 'Continuer',
  },
  online: {
    id: 'teachers.page.teacher.location.onLine',
    defaultMessage: 'Cours en ligne',
  },
  or: {
    id: 'common.or',
    defaultMessage: 'Ou',
  },
  passwordLabel: {
    id: 'common.password.label',
    defaultMessage: 'Mot de passe 🔐',
  },
  passwordValidatorError: {
    id: 'common.password.validator.error',
    defaultMessage: 'Le champ ne peut être vide',
  },
  peacefulTitle: {
    id: 'common.peaceful.title',
    defaultMessage: 'La tranquillité Sherpa',
  },
  peacefulSubtitle: {
    id: 'common.peaceful.subtitle',
    defaultMessage:
      'Notre plateforme est conçue pour vous faciliter la vie. Notre équipe vous répond sous 24h.',
  },
  phoneValidatorError: {
    id: 'common.phone.validator.error',
    defaultMessage: 'Merci de renseigner un numéro de téléphone valide',
  },
  postpone: {
    id: 'common.button.postpone',
    defaultMessage: 'Reporter',
  },
  progressTitle: {
    id: 'common.progress.title',
    defaultMessage: 'Des progrès rapides',
  },
  progressSubtitle: {
    id: 'common.progress.subtitle',
    defaultMessage:
      'Grâce au nombre d’heures de cours par semaine, réalisez des progrès remarquables.',
  },
  refuse: {
    id: 'common.button.refuse',
    defaultMessage: 'Refuser',
  },
  return: {
    id: 'common.return.button',
    defaultMessage: 'Retour',
  },
  save: {
    id: 'common.button.save',
    defaultMessage: 'Enregistrer et continuer',
  },
  saveOnly: {
    id: 'common.button.saveOnly',
    defaultMessage: 'Enregistrer',
  },
  search: {
    id: 'common.button.search',
    defaultMessage: 'Recherche',
  },
  takeSubscription: {
    id: 'section.profile.take.subscription',
    defaultMessage: `{isTeacher , select,
      true { Proposer une formule }
      other { Choisir votre formule } 
    }`,
  },
  videoAlert: {
    id: 'course.card.alert',
    defaultMessage:
      'Le cours d’essai a toujours lieu en visioconférence sur Les Sherpas.',
  },
  warning: {
    id: 'common.helper.warning',
    defaultMessage: 'Attention :',
  },
});
