import { AuthenticatedStudentInfos } from '@les-sherpas/sherpas-toolbox';

class StudentInfosClass {
  constructor(student?: AuthenticatedStudentInfos) {
    this.hasExpiringCard = student?.hasExpiringCard;
  }

  public readonly hasExpiringCard: boolean;
}

export default StudentInfosClass;
