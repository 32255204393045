import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

type Props = {
  width: number;
  height: number;
};
export default makeStyles<Props>()(({ palette }, { width, height }) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: rem(width),
    height: rem(height),
    borderRadius: 1000,
    border: `${rem(2)} solid ${palette.common.white}`,
    backgroundColor: palette.primary.main,
    overflow: 'hidden',
  },
  photo: {
    objectFit: 'cover',
    aspectRatio: '1/1',
  },
  avatarText: {
    color: palette.common.white,
  },
}));
