import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import maxWidthContainer from '@/styles/maxWidth';

type Props = {
  shouldAnimateNavBar: boolean;
};

export default makeStyles<Props>()(({ palette, breakpoints, shadow }) => ({
  root: {
    zIndex: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: rem(68), // automatique normalement mais autant contraindre
    backgroundColor: palette.common.white,
    padding: `${rem(12)} 0`,
    boxShadow: shadow.medium,
    marginBottom: rem(-68), // for navbar
    [breakpoints.up('lg')]: {
      padding: `${rem(16)} 0 ${rem(12)}`,
      height: rem(72),
      marginBottom: rem(-72), // for navbar
    },
  },
  navContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    ...maxWidthContainer,
    margin: `0 ${rem(20)}`,
  },
  navigationMenu: {
    display: 'flex',
  },
  itemNavigation: {
    display: 'flex',
    alignItems: 'center',
    gap: rem(8),
  },
  connexionButton: {
    display: 'flex',
    gap: rem(12),
  },
  linkList: {
    display: 'flex',
    gap: rem(16),
    flexFlow: 'column',
  },
  mobile: {
    display: 'block',
    [breakpoints.up('md')]: {
      display: 'none',
    },
  },
  notMobile: {
    display: 'none',
    [breakpoints.up('md')]: {
      display: 'block',
    },
  },
}));
