import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import noCssButton from '@/styles/noCssButton';

export default makeStyles()(({ palette, breakpoints }) => ({
  sectionFooter: {
    display: 'flex',
    flexFlow: 'column',
    gap: rem(12),
    textAlign: 'center',
    [breakpoints.up('md')]: {
      textAlign: 'left',
      flex: 1,
    },
  },
  linkContainerWithLi: {
    listStyle: 'none',
  },
  sectionLink: {
    display: 'flex',
    flexFlow: 'column',
    gap: rem(8),
    padding: 0,
  },
  label: {
    textAlign: 'center',
  },
  buttonText: {
    color: palette.common.white,
  },
  noCssButton: {
    ...noCssButton,
    padding: 0,
    textAlign: 'center',
    [breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
}));
