import { defineMessages } from 'react-intl';

export default defineMessages({
  button: {
    id: 'ds.onboarding.gafam.button',
    defaultMessage: `{provider, select, 
      google {
        {variant, select,
          login  {Continuer}
          other  {S’inscrire}
        } avec Google
      }
      other {
        {variant, select,
          login  {Continuer}
          other  {S’inscrire}
        } avec Facebook
      }
    }`,
  },
});
