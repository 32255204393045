import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette: { text } }) => ({
  body: {
    gap: rem(8),
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    color: text.secondary,
    textAlign: 'center',
    maxWidth: rem(296),
  },
  title: {
    textAlign: 'center',
    maxWidth: rem(296),
  },
}));
