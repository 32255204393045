import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette }) => ({
  container: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start',
    gap: 8,
    '&.smallBlue': {
      padding: `${rem(4)} ${rem(8)} ${rem(4)} ${rem(4)}`,
      background: palette.supporting.blue.light,
      border: `1px solid ${palette.supporting.blue.main}`,
    },
    '&.bigBlack': {
      padding: `${rem(24)}`,
      background: palette.neutral[900],
      border: 'none',
      width: '100%',
      justifyContent: 'flex-start',
    },
    borderRadius: rem(12),
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  lineHeight: {
    '&.smallBlue': {
      lineHeight: 1.2,
    },
  },
  title: {
    '&.smallBlue': {
      color: palette.supporting.blue.dark,
    },
    '&.bigBlack': {
      color: palette.neutral[500],
    },
  },
  phoneNumber: {
    textDecoration: 'none',
    '&.smallBlue': {
      color: palette.supporting.blue.dark,
    },
    '&.bigBlack': {
      // color: palette.neutral[500],
    },
  },
  iconContainer: {
    display: 'flex',
    borderRadius: rem(8),
    padding: rem(8),
    '&.smallBlue': {
      background: palette.supporting.blue.main,
    },
    '&.bigBlack': {
      padding: rem(12),
      background: palette.neutral[200],
    },
  },
  icon: {
    fontSize: 20,
    color: palette.common.white,
  },
}));
