import { createContext, ReactNode, useContext } from 'react';

type ToastProviderValue = {
  addToast: (_toastComponent: ReactNode) => void;
  onClose: () => void;
};

const ToastContext = createContext<Partial<ToastProviderValue>>({});

export const ToastProvider = ToastContext.Provider;

export const useToastContext = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error(
      'useToastContext can only be used inside the ToastProvider'
    );
  }

  return context;
};
