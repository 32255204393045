import { FC, HTMLAttributes, PropsWithChildren, ReactElement } from 'react';
import { Typography } from '@mui/material';

import { Typo } from '@/designSystem/theme';

import useStyles from './styles';

// TODO: fix variant typography
type Props = {
  label: string | ReactElement;
  variant?: Typo;
  htmlFor?: string;
};

const Form: FC<PropsWithChildren<Props & HTMLAttributes<HTMLLabelElement>>> = ({
  htmlFor,
  label,
  children,
  className = '',
  variant = 'bodyMdBold',
  ...rest
}) => {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.root, className)}>
      <Typography
        variant={variant}
        component="label"
        htmlFor={htmlFor}
        {...rest}
      >
        {label}
      </Typography>
      {children}
    </div>
  );
};
export default Form;
