import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import { getColorFromVariant } from '@/styles/getVariantColor';
import { Variant } from '@/types/Variant';

export default makeStyles<{ variant: Variant }>()((_theme, { variant }) => ({
  root: {
    width: rem(24),
    height: rem(24),
    ...getColorFromVariant(variant),
  },
}));
