import { FC } from 'react';

import { HOME } from '@/data/navbar/navButtons';
import { HEADER_NAVIGATION_ID } from '@/shared/Navbar/constant';
import { useNavbarContext } from '@/shared/Navbar/context/NavbarContext';
import ItemNavigation from '@/shared/Navbar/Items/ItemNavigation/ItemNavigation';
import LinkSherpa from '@/shared/Navbar/Items/LinkSherpa/LinkSherpa';
import LoginButton from '@/shared/Navbar/Items/LoginButton/LoginButton';
import NavbarSearch from '@/shared/Navbar/Items/NavbarSearch/NavbarSearch';
import SignupButton from '@/shared/Navbar/Items/SignupButton/SignupButton';
import useCommonStyles from '@/shared/Navbar/styles';

const NavbarGuestUserDesktop: FC = () => {
  const { isLandingPage, route } = useNavbarContext();
  const { classes: commonClasses } = useCommonStyles({
    shouldAnimateNavBar: isLandingPage,
  });

  return (
    <header className={commonClasses.root} id={HEADER_NAVIGATION_ID}>
      <div className={commonClasses.navContainer}>
        <div className={commonClasses.navigationMenu}>
          <LinkSherpa href={HOME} />
          <NavbarSearch />
        </div>

        <div className={commonClasses.itemNavigation}>
          <ItemNavigation activeLink={route} />

          <div className={commonClasses.connexionButton}>
            <LoginButton />
            <SignupButton />
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavbarGuestUserDesktop;
