import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { ChatSocketServerToClientEvents } from '@les-sherpas/sherpas-toolbox';
import axios from 'axios';
import server from 'config';
import io, { Socket } from 'socket.io-client';

import useAuthentication from '@/components/Authentication/useAuthentication';

import {
  ClientToServerEvents,
  ServerToClientEvents,
  SocketProvider as SocketContextProvider,
} from './Socket.context';

const SocketProvider: FC<PropsWithChildren> = ({ children }) => {
  const [socket, setSocket] =
    useState<Socket<ServerToClientEvents, ClientToServerEvents>>();
  const { authentication } = useAuthentication();
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [notificationsCount, setNotificationsCount] = useState(0);

  useEffect(() => {
    if (authentication?.isAuthenticated && isSocketConnected) {
      axios.get(`${server}notification/count`, { withCredentials: true });
    }
  }, [authentication, isSocketConnected]);

  useEffect(() => {
    if (!socket || !isSocketConnected) return;

    socket.on(ChatSocketServerToClientEvents.NotificationCount, (count) =>
      setNotificationsCount(count)
    );

    // eslint-disable-next-line consistent-return
    return () => {
      socket.off(ChatSocketServerToClientEvents.NotificationCount);
    };
  }, [socket, isSocketConnected]);

  // @ts-ignore
  useEffect(() => {
    if (authentication?.isAuthenticated) {
      const newSocket = io(server, {
        withCredentials: true,
        reconnectionAttempts: 5,
        transports: ['websocket', 'polling'],
      });
      setSocket(newSocket);
    }
    return () => {
      socket?.close();
    };
  }, [authentication?.authData?.id]);

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => setIsSocketConnected(true));
      socket.on('disconnect', () => setIsSocketConnected(false));
    }
  }, [socket]);

  const context = {
    socket,
    notificationsCount,
    isSocketConnected,
  };

  return (
    <SocketContextProvider value={context}>{children}</SocketContextProvider>
  );
};

export default SocketProvider;
