import { FC } from 'react';

import useStyles from './styles';

type Props = {
  completionPercentage: number;
};

const ProgressBarDonut: FC<Props> = ({ completionPercentage }) => {
  const { classes } = useStyles();
  const hasSomeProgression = completionPercentage > 0;

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-6.5 -6.5 65 65"
      className={classes.container}
    >
      <circle
        r="16"
        cx="26"
        cy="26"
        strokeDasharray="100.48px"
        className={classes.progressBarBackground}
      />
      {hasSomeProgression && (
        <circle
          r="16"
          cx="26"
          cy="26"
          strokeDasharray="100.48px"
          strokeDashoffset={Math.max(0, 100 - completionPercentage)}
          className={classes.progressBarValue}
        />
      )}
    </svg>
  );
};

export default ProgressBarDonut;
