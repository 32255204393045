import { useEffect } from 'react';
import { UserType } from '@les-sherpas/sherpas-toolbox';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { AuthenticationType, getAuth } from './Authentication.utils';

export const AUTHENTICATION_KEY = 'authentication-with-userType';

const useAuthentication = () => {
  const { data: authentication, isLoading } = useQuery(
    [AUTHENTICATION_KEY],
    () => getAuth(),
    { refetchOnWindowFocus: true, cacheTime: 0 }
  );

  return { authentication, isLoading };
};

export const usePreFetchAuthentication = () => {
  const queryClient = useQueryClient();
  return {
    prefetchAuthentication: () =>
      queryClient.prefetchQuery([AUTHENTICATION_KEY], () => getAuth()),
    refetchAuthentication: () =>
      queryClient.refetchQueries([AUTHENTICATION_KEY, { active: true }]),
  };
};

export const useIsAuthenticatedOrBack = (): AuthenticationType => {
  const { authentication } = useAuthentication();
  const { replace } = useRouter();

  useEffect(() => {
    if (!authentication) {
      return;
    }
    if (!authentication?.isAuthenticated) {
      replace('/');
    }
  }, [authentication, replace]);

  return authentication;
};

export const useIsTeacherAuthenticatedOrBack = (): AuthenticationType => {
  const { authentication } = useAuthentication();
  const { replace } = useRouter();

  useEffect(() => {
    if (!authentication) {
      return;
    }
    if (
      !authentication?.isAuthenticated ||
      authentication?.authData?.userType === UserType.Student
    ) {
      replace('/');
    }
  }, [authentication, replace]);

  return authentication;
};

export const useIsStudentAuthenticatedOrBack = ({
  onNotAuthenticated,
  onNotStudent,
}: {
  onNotAuthenticated?: () => void;
  onNotStudent?: () => void;
} = {}) => {
  const { authentication, isLoading } = useAuthentication();
  const { replace } = useRouter();

  useEffect(() => {
    const goHome = () => replace('/');

    if (!authentication) {
      return;
    }

    if (!authentication.isAuthenticated) {
      if (onNotAuthenticated) {
        onNotAuthenticated();
      } else {
        goHome();
      }
      return;
    }

    if (authentication.authData.userType !== UserType.Student) {
      if (onNotStudent) {
        onNotStudent();
      } else {
        goHome();
      }
    }
  }, [authentication, onNotAuthenticated, onNotStudent, replace]);

  return { authentication, isLoading };
};

export default useAuthentication;
