import { FC, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';

import useStyles from './styles';

export type TagProps = {
  variant: string;
  label: string;
};

const Tag: FC<PropsWithChildren<TagProps>> = ({ label, variant }) => {
  const isPositionStart = variant === 'start';
  const { classes } = useStyles({ isPositionStart });

  return (
    <div className={classes.root}>
      <Typography variant="bodyMd">{label}</Typography>
    </div>
  );
};
export default Tag;
