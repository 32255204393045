import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import noCssButton from '@/styles/noCssButton';

export default makeStyles()(({ palette: { supporting } }) => ({
  formContainer: {
    display: 'flex',
    flexFlow: 'column',
    gap: rem(20),
  },
  fullWidth: {
    flex: 1,
  },
  connect: {
    color: supporting.blue.main,
  },
  button: { ...noCssButton, marginTop: rem(8) },
}));
