import { FC } from 'react';

import useStyles from './styles';

const NotificationChip: FC<{
  isShown: boolean;
  isFloating?: boolean;
}> = ({ isShown, isFloating = false }) => {
  const { classes } = useStyles({ isFloating });

  return isShown && <div className={classes.redCircle} />;
};

export default NotificationChip;
