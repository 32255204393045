import { FC } from 'react';
import dynamic from 'next/dynamic';

import NavbarSeoDesktop from '@/shared/Navbar/Variants/NavbarSeo/NavbarSeoDesktop/NavbarSeoDesktop';
import { useIsMobile, useResponsiveClasses } from '@/utils/useResponsive';

const NavbarSeoMobile = dynamic(
  () =>
    import(
      '@/shared/Navbar/Variants/NavbarSeo/NavbarSeoMobile/NavbarSeoMobile'
    ),
  { ssr: true }
);

const NavbarSeo: FC = () => {
  const isMobile = useIsMobile();
  const { classes: responsiveClasses } = useResponsiveClasses();

  return isMobile ? (
    <NavbarSeoMobile />
  ) : (
    <div className={responsiveClasses.desktop}>
      <NavbarSeoDesktop />
    </div>
  );
};

export default NavbarSeo;
