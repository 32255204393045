import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

type StyleProps = {
  isFloating: boolean;
};

export default makeStyles<StyleProps>()(({ palette }, { isFloating }) => ({
  redCircle: {
    position: isFloating ? 'absolute' : 'unset',
    top: rem(-4),
    right: rem(-4),
    display: 'inline-block',
    marginLeft: rem(4),
    width: rem(8),
    height: rem(8),
    borderRadius: '50%',
    backgroundColor: palette.supporting.red.main,
  },
}));
