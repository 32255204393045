import { FC, HTMLAttributes } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Rating, Typography } from '@mui/material';

import Avatar from '@/Molecules/Avatar/Avatar';

import messages from './messages';

import useStyles from './styles';

export type LoginSignUpModalTeacherInfosProps = {
  firstName: string;
  lastName: string;
  photoUrl: string;
  rating?: number;
  numberOfRecommandation?: number;
  isVerifiedAndTrained?: boolean;
  isQuickAnswer?: boolean;
};

const getMessage = (isVerifiedAndTrained, isQuickAnswer) => {
  let message = '';
  if (isVerifiedAndTrained) {
    message += 'Certified';
  }
  if (isQuickAnswer) {
    message += 'QuickAnswer';
  }
  return message;
};

const LoginSignUpModalTeacher: FC<
  LoginSignUpModalTeacherInfosProps & HTMLAttributes<HTMLDivElement>
> = ({
  firstName,
  lastName,
  photoUrl,
  rating,
  numberOfRecommandation,
  isVerifiedAndTrained = false,
  isQuickAnswer = false,
  className,
}) => {
  const { cx, classes } = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={cx(classes.container, className)}>
      <Avatar
        firstName={firstName}
        lastName={lastName}
        photoUrl={photoUrl}
        width={64}
        height={64}
        className={classes.avatar}
      />
      {rating && (
        <Rating
          defaultValue={rating}
          readOnly
          precision={0.5}
          size="small"
          getLabelText={() =>
            formatMessage(messages.accessibilite, {
              rating,
            })
          }
        />
      )}
      {numberOfRecommandation > 0 && (
        <Typography
          variant="bodyLg"
          component="p"
          className={classes.description}
        >
          <FormattedMessage
            {...messages.ratings}
            values={{
              numberOfRecommandation,
            }}
          />
        </Typography>
      )}
      <Typography
        variant="bodyLg"
        component="p"
        className={classes.description}
      >
        <FormattedMessage
          {...messages.description}
          values={{
            message: getMessage(isVerifiedAndTrained, isQuickAnswer),
            firstName,
          }}
        />
      </Typography>
    </div>
  );
};

export default LoginSignUpModalTeacher;
