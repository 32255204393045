import { FC } from 'react';
import dynamic from 'next/dynamic';

import NavbarAuthUserDesktop from '@/shared/Navbar/Variants/NavbarAuthUser/NavbarAuthUserDesktop/NavbarAuthUserDesktop';
import { useIsMobile, useResponsiveClasses } from '@/utils/useResponsive';

const NavbarAuthUserMobile = dynamic(
  () =>
    import(
      '@/shared/Navbar/Variants/NavbarAuthUser/NavbarAuthUserMobile/NavbarAuthUserMobile'
    ),
  { ssr: true }
);

const NavbarAuthUser: FC = () => {
  const isMobile = useIsMobile();
  const { classes: responsiveClasses } = useResponsiveClasses();

  return isMobile ? (
    <NavbarAuthUserMobile />
  ) : (
    <div className={responsiveClasses.desktop}>
      <NavbarAuthUserDesktop />
    </div>
  );
};

export default NavbarAuthUser;
