const noCssButton = {
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  color: 'inherit',
  font: 'inherit',
  outline: 'inherit',
};

export default noCssButton;
