import { ImageLoader } from 'next/image';

const OLD_S3_URL = process.env.NEXT_PUBLIC_OLD_S3_URL;
const OLD_CDN_URL = process.env.NEXT_PUBLIC_OLD_CDN_URL;
const S3_URL = process.env.NEXT_PUBLIC_S3_URL;
const CDN_CLOUDIMAGE_URL = process.env.NEXT_PUBLIC_CDN_CLOUDIMAGE_URL;

export const BLUR_IMAGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMUFRGpBwABQQC+j7VjogAAAABJRU5ErkJggg==';

const getImageFromCDN =
  (URL_CDN: string): ImageLoader =>
  ({ src, width, quality = 75 }) => {
    let url = src.replace(OLD_S3_URL, OLD_CDN_URL).replace(S3_URL, URL_CDN);
    if (URL_CDN === CDN_CLOUDIMAGE_URL) {
      url = `${URL_CDN}${src}`;
    }

    return `${url}?w=${width}&q=${quality}&force_format=webp`;
  };

export const getImageFromCloudImage: ImageLoader =
  getImageFromCDN(CDN_CLOUDIMAGE_URL);
