import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  ModalFooter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: rem(32),
    gap: rem(12),
  },
}));
