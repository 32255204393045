import { defineMessages } from 'react-intl';

export default defineMessages({
  searchPlaceholder: {
    id: 'search.bar.search.placeholder',
    defaultMessage:
      'Cherchez une matière : Maths, Physique, Anglais et plus...',
  },
  searchAriaLabel: {
    id: 'search.bar.search.aria.label',
    defaultMessage: 'Rechercher une matière',
  },
  locationPlaceholder: {
    id: 'search.bar.location.placeholder',
    defaultMessage: 'Adresse, ville...',
  },
  locationAriaLabel: {
    id: 'search.bar.location.aria.label',
    defaultMessage: 'Rechercher une adresse',
  },
  search: {
    id: 'search.bar.search',
    defaultMessage: 'Rechercher',
  },
});
