import { urls } from '@/shared/Hooks/useNavigation';

export const BLOG = 'https://sherpas.com/blog/';

export const HOME = '/';
export const DMR_DRAWER = '/paiement';
export const SCHEDULE_COURSE_DRAWER = '/planification-cours';
export const SIGN_UP_PATH = '/signup';
export const SHERPA = '/sherpa'; // TODO: Supprimer, route inutilisée
export const MESSAGES = '/messages';
export const RESOURCES = '/ressources';
export const ANNOUNCES = '/annonce';
export const SEARCH = '/professeurs';
export const PRIVATE_COURSE = '/donner-cours-particuliers';
export const DASHBOARD = '/tableau-de-bord';
export const DASHSTATS = `${DASHBOARD}/revenus`;
export const DASHRATING = `${DASHBOARD}/suivi`;
export const DASHFAVORITES = `${DASHBOARD}/favoris`;
export const INSCRIPTION = '/inscription';
export const LOGIN = '/login';
export const HELP_CENTER = '/centre-aide';
export const ONBOARDING_TEACHER_FROM_GAFAM = urls.sherpaSignUp({ step: 3 });
export const ONBOARDING_TEACHER_FROM_EMAIL = '/sherpa/signup';
export const PRIVACY_POLICY = '/mentions-legales';
export const INFLUENCER_MAMAN_URL = '/mamanjusquauboutdesongles';
export const STUDENT_NEED = '/student-need';
export const PARAMETERS = '/parametre';
export const BILL_AND_PAY = `${PARAMETERS}/facture-et-paiement`;
export const REDIRECT_URL_ITEM = 'redirectUrl';
export const B2B_SEARCH = 'b2b_search';
export const ONBOARDING_STUDENT_DRAWER_SETTINGS =
  'onboarding_student_drawer_settings';
export const SPONSORING_DRAWER = '/sponsoring';
export const VISIO_WAITING_SCREEN = `${DASHBOARD}/visio/`;
