import { AuthApi } from '@les-sherpas/sherpas-toolbox';
import axios from 'axios';
import server from 'config';

import Authentication from '@/components/Authentication/Authentication.class';
import StudentInfosClass from '@/components/Authentication/StudentInfo.class';
import TeacherInfosClass from '@/components/Authentication/TeacherInfo.class';
import logout from '@/Molecules/User/User.utils';

export type AuthenticationType = {
  isAuthenticated: boolean;
  authData: Authentication;
  teacherInfo: TeacherInfosClass;
  studentInfo: StudentInfosClass;
};

export const getAuth = async (): Promise<AuthenticationType> => {
  try {
    const { data: authData, status } = await axios.get<
      AuthApi['private']['getAuthenticatedUserInfos']['response'] | undefined
    >(`${server}auth/private/get-authenticated-user-infos`, {
      withCredentials: true,
      validateStatus: (status) => status === 200 || status === 403,
      headers: {
        'Cache-Control':
          'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      },
    });

    if (status === 403) {
      // Permet d'invalider le cookie pour éviter les call DB ensuite
      await logout();
      return {
        isAuthenticated: false,
        authData: new Authentication(),
        teacherInfo: new TeacherInfosClass(),
        studentInfo: new StudentInfosClass(),
      };
    }

    return {
      isAuthenticated: true,
      authData: new Authentication(authData),
      studentInfo: new StudentInfosClass(authData.studentInfos),
      teacherInfo: new TeacherInfosClass(authData.teacherInfos),
    };
  } catch (e) {
    return {
      isAuthenticated: false,
      authData: new Authentication(),
      teacherInfo: new TeacherInfosClass(),
      studentInfo: new StudentInfosClass(),
    };
  }
};
