import { createContext, useContext } from 'react';

type SponsoringContextValue = {
  sponsorFirstName: string | null;
  sponsoringCode: string | null;
  hasSponsorLink: boolean;
  userBuyDmr: () => void;
};

export const SponsoringContext = createContext<SponsoringContextValue>(
  {} as SponsoringContextValue
);

export const useSponsoringContext = () => {
  const context = useContext(SponsoringContext);
  if (!context) {
    throw new Error(
      "le context ne peut pas être utilisé si le provider SponsoringProvider n'a pas été setté"
    );
  }
  return context;
};
