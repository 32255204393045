import { normalizeString } from '@les-sherpas/sherpas-toolbox';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { Subject } from '@/types/subject';

export type HighlightReturn = { text: string; highlight: boolean };

export const orderSubjects = (
  // TODO: à fix
  // eslint-disable-next-line default-param-last
  datas: Subject[] = [],
  inputValue: string
): HighlightReturn[][] => {
  if (!inputValue) return [];
  const search = normalizeString(inputValue);
  const sortedDatas = datas.sort((a, b) => b.score - a.score);
  return [
    ...sortedDatas
      .map(({ name }) => {
        if (normalizeString(name).startsWith(search)) {
          const matches = match(name, search, { insideWords: false });

          return parse(name, matches).sort();
        }
        return [{ text: '', highlight: false }];
      })
      .filter((options) => options.every(({ text }) => text !== '')),

    ...sortedDatas
      .map(({ name }) => {
        if (
          normalizeString(name).includes(search) &&
          !normalizeString(name).startsWith(search)
        ) {
          const matches = match(name, search, { insideWords: true });
          return parse(name, matches);
        }
        return [{ text: '', highlight: false }];
      })
      .filter((options) => options.every(({ text }) => text !== '')),
  ];
};

export const orderInstitutionData = (datas, inputValue) =>
  datas
    ?.map((opt) => {
      if (match(opt, inputValue)) {
        const matches = match(opt, inputValue, { insideWords: false });

        return parse(opt, matches);
      }
      return { text: '' };
    })
    .filter((opt) => opt.text !== '');

export const orderLocationData = (datas, inputValue) =>
  datas
    ?.map((opt) => {
      const matches = match(opt, inputValue);
      return parse(opt, matches);
    })
    .filter((opt) => opt.text !== '');
