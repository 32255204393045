import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles<{ isScrollable: boolean }>()(
  (theme, { isScrollable }) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadow.high,
      padding: `${rem(20)} ${rem(16)}`,
      borderRadius: rem(20),
      outline: 'none',
      position: 'relative',
      overflow: 'auto',
      [theme.breakpoints.up('md')]: {
        padding: rem(32),
      },
    },
    modalSize: {
      maxWidth: rem(480),
    },
    focuslock: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflowY: isScrollable ? 'auto' : 'hidden',
    },
    backdrop: {
      cursor: 'initial',
      display: 'flex',
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      padding: rem(10),
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      zIndex: 100,
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      opacity: '1',
      transition: 'opacity 0.5s ease',
    },
  })
);
