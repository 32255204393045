import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints }) => ({
  bottomFooter: {
    display: 'flex',
    boxShadow: `inset 0px 1px 0px ${palette.neutral[800]}`,
    padding: `${rem(16)} ${rem(10)}`,
    gap: rem(12),
    flexFlow: 'column',
    [breakpoints.up('md')]: {
      flexFlow: 'row',
      justifyContent: 'space-between',
      padding: `${rem(18)} 0 ${rem(18)} ${rem(10)}`,
    },
    [breakpoints.up('lg')]: {
      padding: `${rem(16)} ${rem(18)}`,
    },
  },
  text: {
    color: palette.neutral[500],
  },
  svg: {
    display: 'flex',
    gap: rem(14),
    flex: 1,
    justifyContent: 'center',
    [breakpoints.up('md')]: {
      flex: 'none',
    },
  },
}));
