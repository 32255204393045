import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SponsoringApi } from '@les-sherpas/sherpas-toolbox';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import useAuthentication from '@/components/Authentication/useAuthentication';
import { SponsoringContext } from '@/shared/SponsoringPromoBanner/context/SponsoringContext';
import useLocalStorageSponsor from '@/shared/SponsoringPromoBanner/useLocalStorageSponsor';

const SponsoringProvider: FC<PropsWithChildren> = ({ children }) => {
  const { query, push, pathname } = useRouter();
  const { authentication } = useAuthentication();
  const [sponsoringCode, setSponsoringCode] = useState<string | null>(null);
  const [sponsorFirstName, setSponsorFirstName] = useState<string | null>(null);
  const {
    setSponsorInLocalStorage,
    removeSponsorInLocalStorage,
    getSponsorInLocalStorage,
  } = useLocalStorageSponsor();

  // Cette requête est lancée sur la page de parrainage uniquement (contient le sponsoringCode dans l'url)
  // Le séquencement est important sur cette requête: elle doit être lancée une fois que le process d'authentification est terminé
  // est terminé
  // Et bien sûr on recherche l'info du sponsor qui si l'utilisateur est déconnecté ou n'a pas encore été parrainé
  const querySponsoringCode = query?.sponsoringCode as string | undefined;
  const { data } = useQuery<SponsoringApi['public']['getSponsor']['response']>(
    [`sponsoring/public/get-sponsor/${querySponsoringCode}`],
    {
      enabled:
        Boolean(querySponsoringCode) &&
        Boolean(authentication) &&
        (!authentication.isAuthenticated ||
          authentication.authData.canBeSponsored),
    }
  );

  useEffect(() => {
    if (querySponsoringCode) {
      const newQuery = { ...query };
      delete newQuery?.sponsoringCode;

      push(
        {
          pathname,
          query: newQuery,
        },
        undefined,
        { shallow: true } // Ne recharge pas la page
      );
    }
  }, [pathname, push, query, querySponsoringCode]);

  // Pour les pages qui ne sont pas le lien de parrainage
  // On charge les informations du localStorage
  useEffect(() => {
    if (!querySponsoringCode) {
      const sponsor = getSponsorInLocalStorage();
      setSponsoringCode(sponsor?.sponsoringCode);
      setSponsorFirstName(sponsor?.sponsorFirstName);
    }
  }, [querySponsoringCode, getSponsorInLocalStorage]);

  // Pour les pages authentifiés on check que la personne est elligible au parrainage
  useEffect(() => {
    if (authentication) {
      if (data?.sponsor) {
        if (authentication.authData.id !== data.sponsor.id) {
          setSponsoringCode(data.sponsor.sponsoringCode);
          setSponsorFirstName(data.sponsor.firstName);
          setSponsorInLocalStorage({
            sponsoringCode: data.sponsor.sponsoringCode,
            sponsorFirstName: data.sponsor.firstName,
          });
        }
      }

      if (authentication.isAuthenticated) {
        if (!authentication.authData.canBeSponsored) {
          setSponsoringCode(null);
          setSponsorFirstName(null);
          removeSponsorInLocalStorage();
        }
      }
    }
  }, [
    data,
    authentication,
    setSponsorInLocalStorage,
    removeSponsorInLocalStorage,
  ]);

  const userBuyDmr = useCallback(() => {
    setSponsoringCode(null);
    setSponsorFirstName(null);
    removeSponsorInLocalStorage();
  }, [removeSponsorInLocalStorage]);

  const context = useMemo(
    () => ({
      sponsoringCode,
      sponsorFirstName,
      hasSponsorLink: Boolean(sponsoringCode && sponsorFirstName),
      userBuyDmr,
    }),
    [sponsorFirstName, sponsoringCode, userBuyDmr]
  );

  return (
    <SponsoringContext.Provider value={context}>
      {children}
    </SponsoringContext.Provider>
  );
};

export default SponsoringProvider;
