import { FC, HTMLAttributes, ReactEventHandler } from 'react';
import { useIntl } from 'react-intl';

import Button from '@/designSystem/Button/Button';
import { LoginSignUpVariant } from '@/Organisms/LoginSignUpModal/LoginSignUp.type';

import messages from './message';

type Props = {
  variant?: LoginSignUpVariant;
  onClick: ReactEventHandler;
};

const EmailButton: FC<HTMLAttributes<HTMLButtonElement> & Props> = ({
  variant = 'login',
  onClick,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Button
      variant="secondary"
      onClick={onClick}
      isFullWidth
      label={formatMessage(messages.button, { variant })}
      icon="mail"
    />
  );
};

export default EmailButton;
