import { FC, PropsWithChildren } from 'react';

const Facebook: FC<PropsWithChildren<unknown>> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_362_790)">
      <path
        d="M12 22.0013C17.5228 22.0013 22 17.5241 22 12.0013C22 6.47848 17.5228 2.00134 12 2.00134C6.47715 2.00134 2 6.47848 2 12.0013C2 17.5241 6.47715 22.0013 12 22.0013Z"
        fill="#1977F3"
      />
      <path
        d="M15.8926 14.8927L16.3356 12.0014H13.5624V10.1254C13.5624 9.33511 13.9491 8.56306 15.1923 8.56306H16.4537V6.10209C16.4537 6.10209 15.309 5.90662 14.2149 5.90662C11.9311 5.90662 10.4377 7.29039 10.4377 9.79777V12.0014H7.89795V14.8927H10.4377V21.8805C10.9467 21.9606 11.4685 22.0014 12 22.0014C12.5316 22.0014 13.0533 21.9592 13.5624 21.8805V14.8927H15.8926Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_362_790">
        <rect width="20" height="20" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

export default Facebook;
