import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  container: {
    minHeight: rem(68),
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 20,
  },
}));
