import { defineMessages } from 'react-intl';

export default defineMessages({
  withLove: {
    id: 'ds.footer.with.love',
    defaultMessage: '© Sherpas. Fait avec ❤️ à Paris, France.',
  },
  ariaLabel: {
    id: 'ds.footer.ariaLabel',
    defaultMessage: `{socialNetwork, select, 
      facebook {Suivez-nous sur Facebook}
      instagram {Suivez-nous sur Instagram}
      youtube {Suivez-nous sur YouTube}
      tiktok {Suivez-nous sur TikTok}
      other {}
    }`,
  },
});
