import { FC } from 'react';

import iconMapping from '@/designSystem/Icon/Icon.data';
import IconBookOpen from '@/designSystem/Icons/IconBookOpen';

import useStyles from './styles';

type IconSize = 'small' | 'medium' | 'large' | 'xlarge';

export type IconColor =
  | 'accent'
  | 'accentEmphasis'
  | 'attention'
  | 'attentionEmphasis'
  | 'brand'
  | 'brandEmphasis'
  | 'danger'
  | 'dangerEmphasis'
  | 'neutral'
  | 'neutralEmphasis'
  | 'success'
  | 'successEmphasis'
  | 'subtle'
  | 'white';

type Props = {
  icon: keyof typeof iconMapping;
  size?: IconSize;
  color?: IconColor;
};

const Icon: FC<Props> = ({ icon, color, size = 'medium' }) => {
  const getIconSize = (size: IconSize) => {
    let iconSize: number;

    switch (size) {
      case 'small':
        iconSize = 12;
        break;
      case 'large':
        iconSize = 20;
        break;
      case 'xlarge':
        iconSize = 24;
        break;
      case 'medium':
      default:
        iconSize = 16;
        break;
    }
    return iconSize;
  };
  const { classes, cx } = useStyles({ size: getIconSize(size) });

  const BaseIcon = iconMapping[icon] ? iconMapping[icon] : IconBookOpen;

  return <BaseIcon className={cx(classes[color], classes.common)} />;
};

export default Icon;
