import { useCallback } from 'react';

function useLocalStorage<T>(key: string) {
  const setItem = useCallback(
    (value: T) => {
      localStorage.setItem(
        key,
        typeof value === 'object' ? JSON.stringify(value) : String(value)
      );
    },
    [key]
  );

  const getItem = useCallback((): T | undefined => {
    let item;
    try {
      item = localStorage.getItem(key);
    } catch (e) {
      // silently caught error
    }
    if (item === undefined) return item;

    try {
      const value = JSON.parse(item) as T;
      return value;
    } catch (e) {
      return item as T;
    }
  }, [key]);

  const removeItem = useCallback(() => {
    localStorage.removeItem(key);
  }, [key]);

  const popItem = useCallback((): T | undefined => {
    const item = getItem();
    removeItem();
    return item;
  }, [getItem, removeItem]);

  return {
    getItem,
    setItem,
    removeItem,
    popItem,
  };
}

export default useLocalStorage;
