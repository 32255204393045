import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles<{ isPositionStart: boolean }>()(
  (_theme, { isPositionStart }) => ({
    adornment: {
      display: 'flex',
      order: isPositionStart ? 0 : 2,
      padding: isPositionStart
        ? `${rem(12)} 0 ${rem(12)} ${rem(12)}`
        : `${rem(12)} ${rem(12)} 0 ${rem(12)}`,
      '& svg': {
        height: rem(15),
        width: rem(15),
      },
    },
  })
);
