import { FC } from 'react';
import { Typography } from '@mui/material';
import Image from 'next/image';

import { getHeightAndWidth } from '@/designSystem/Avatar/styles.utils';
import { getImageFromCloudImage } from '@/utils/cloudFrontLoader';

import useStyles from './styles';

type AvatarSize =
  | '2xlarge'
  | 'xlarge'
  | 'large'
  | 'medium'
  | 'small'
  | 'xsmall';

export type AvatarProps = {
  firstName: string;
  lastName: string;
  photoUrl?: string;
  avatarSize: AvatarSize;
  className?: string;
};

const getInitials = ({
  firstName,
  lastName,
  avatarSize,
}: Pick<AvatarProps, 'firstName' | 'lastName' | 'avatarSize'>) => {
  const hasOnlyOneInitial = ['small', 'xsmall'].includes(avatarSize);
  const firstNameFirstLetter = firstName.charAt(0).toUpperCase();
  const lastNameFirstLetter = lastName?.charAt(0).toUpperCase() ?? '';
  const initials = hasOnlyOneInitial
    ? firstNameFirstLetter
    : `${firstNameFirstLetter}${lastNameFirstLetter}`;

  return initials;
};

const Avatar: FC<AvatarProps> = ({
  photoUrl,
  firstName,
  lastName,
  avatarSize,
  className,
}) => {
  const { width, height } = getHeightAndWidth({ avatarSize });
  const { classes, cx } = useStyles({ width, height });

  const initials = getInitials({ firstName, lastName, avatarSize });
  const alt = `${firstName} ${lastName}`;

  return (
    <div className={cx(classes.container, className)}>
      {photoUrl ? (
        <Image
          src={photoUrl}
          alt={alt}
          loader={getImageFromCloudImage}
          className={classes.photo}
          width={width}
          height={height}
        />
      ) : (
        <Typography
          className={classes.avatarText}
          variant="bodySmBold"
          aria-label={alt}
        >
          {initials}
        </Typography>
      )}
    </div>
  );
};

export default Avatar;
