import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/BaseSvg';

const IconCertifiedOutlined: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.61059 2.60803C8.98746 1.43467 11.0126 1.43467 12.3894 2.60803C12.7125 2.88336 13.1142 3.04975 13.5373 3.08352C15.3406 3.22742 16.7726 4.65938 16.9165 6.46266C16.9503 6.8858 17.1167 7.28751 17.392 7.61059C18.5653 8.98746 18.5653 11.0126 17.392 12.3894C17.1167 12.7125 16.9503 13.1142 16.9165 13.5373C16.7726 15.3406 15.3406 16.7726 13.5373 16.9165C13.1142 16.9503 12.7125 17.1167 12.3894 17.392C11.0126 18.5653 8.98746 18.5653 7.61059 17.392C7.28751 17.1167 6.8858 16.9503 6.46266 16.9165C4.65938 16.7726 3.22742 15.3406 3.08352 13.5373C3.04975 13.1142 2.88336 12.7125 2.60803 12.3894C1.43467 11.0126 1.43467 8.98746 2.60803 7.61059C2.88336 7.28751 3.04975 6.8858 3.08352 6.46266C3.22742 4.65938 4.65938 3.22742 6.46266 3.08352C6.8858 3.04975 7.28751 2.88336 7.61059 2.60803ZM11.3084 3.87656C10.5545 3.23405 9.44556 3.23405 8.69162 3.87656C8.1016 4.37937 7.36799 4.68324 6.59524 4.7449C5.60781 4.8237 4.8237 5.60781 4.7449 6.59524C4.68324 7.36799 4.37937 8.1016 3.87656 8.69162C3.23405 9.44556 3.23405 10.5545 3.87656 11.3084C4.37937 11.8984 4.68324 12.632 4.7449 13.4048C4.8237 14.3922 5.60781 15.1763 6.59524 15.2551C7.36799 15.3168 8.1016 15.6206 8.69162 16.1235C9.44556 16.766 10.5545 16.766 11.3084 16.1235C11.8984 15.6206 12.632 15.3168 13.4048 15.2551C14.3922 15.1763 15.1763 14.3922 15.2551 13.4048C15.3168 12.632 15.6206 11.8984 16.1235 11.3084C16.766 10.5545 16.766 9.44556 16.1235 8.69162C15.6206 8.1016 15.3168 7.36799 15.2551 6.59524C15.1763 5.60781 14.3922 4.8237 13.4048 4.7449C12.632 4.68324 11.8984 4.37937 11.3084 3.87656ZM13.0893 7.74408C13.4147 8.06952 13.4147 8.59716 13.0893 8.92259L9.75593 12.2559C9.43049 12.5814 8.90285 12.5814 8.57742 12.2559L6.91075 10.5893C6.58531 10.2638 6.58531 9.73619 6.91075 9.41075C7.23619 9.08531 7.76382 9.08531 8.08926 9.41075L9.16667 10.4882L11.9108 7.74408C12.2362 7.41865 12.7638 7.41865 13.0893 7.74408Z"
    />
  </BaseSvg>
);

export default IconCertifiedOutlined;
