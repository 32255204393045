import rem from 'polished/lib/helpers/rem';

import themeFoundation from '@/designSystem/theme';

import type { StyleProps } from './styles';

export const getBorderRadius = ({
  size,
  cornerSide,
}: {
  size: StyleProps['size'];
  cornerSide: StyleProps['cornerSide'];
}) => {
  switch (size) {
    case 'small':
      switch (cornerSide) {
        case 'left':
          return `${rem(4)} 0 0 ${rem(4)}`;
        case 'right':
          return `0 ${rem(4)} ${rem(4)} 0`;
        case 'all':
          return rem(4);
        case 'none':
        default:
          return 0;
      }
    case 'medium':
    case 'large':
    default:
      switch (cornerSide) {
        case 'left':
          return `${rem(8)} 0 0 ${rem(8)}`;
        case 'right':
          return `0 ${rem(8)} ${rem(8)} 0`;
        case 'all':
          return rem(8);
        case 'none':
        default:
          return 0;
      }
  }
};

export const getPadding = ({
  size,
  withIcon,
  withText,
}: {
  size: StyleProps['size'];
  withIcon: boolean;
  withText: boolean;
}) => {
  if (withIcon && withText) {
    switch (size) {
      case 'small':
        return `${rem(4)} ${rem(16)} ${rem(4)} ${rem(12)}`;
      case 'medium':
        return `${rem(12)}  ${rem(24)} ${rem(12)}  ${rem(20)}`;
      case 'large':
      default:
        return `${rem(16)} ${rem(28)} ${rem(16)} ${rem(24)}`;
    }
  } else if (withIcon) {
    switch (size) {
      case 'small':
        return `${rem(4)} ${rem(8)}`;
      case 'medium':
        return `${rem(12)}  ${rem(16)}`;
      case 'large':
      default:
        return `${rem(16)} ${rem(20)}`;
    }
  }

  switch (size) {
    case 'small':
      return `${rem(4)} ${rem(16)}`;
    case 'medium':
      return `${rem(12)}  ${rem(24)}`;
    case 'large':
    default:
      return `${rem(16)} ${rem(28)}`;
  }
};

export const getBackgroundColor = ({
  variant,
  color,
  isDisabled,
}: {
  variant: StyleProps['variant'];
  color: StyleProps['color'];
  isDisabled: StyleProps['isDisabled'];
}) => {
  switch (variant) {
    case 'primary':
      if (isDisabled) return themeFoundation.palette.neutral[800];

      switch (color) {
        case 'danger':
          return themeFoundation.palette.supporting.red[300];
        case 'brand':
        default:
          return themeFoundation.palette.neutral.dark;
      }
    case 'secondary':
      if (isDisabled) return themeFoundation.palette.neutral.light;
      return themeFoundation.palette.common.white;
    case 'tertiary':
    case 'link':
      return 'transparent';
    case 'ghost':
    default:
      return 'transparent';
  }
};

export const getIconAndTypographyColor = ({
  variant,
  color,
  isDisabled,
}: {
  variant: StyleProps['variant'];
  color: StyleProps['color'];
  isDisabled: StyleProps['isDisabled'];
}) => {
  if (isDisabled) return themeFoundation.palette.neutral[600];

  switch (variant) {
    case 'primary':
      return themeFoundation.palette.common.white;
    case 'link':
      return themeFoundation.palette.supporting.blue[500];
    case 'secondary':
    case 'tertiary':
    case 'ghost':
    default:
      switch (color) {
        case 'brand':
          return themeFoundation.palette.neutral[200];
        case 'danger':
        default:
          return themeFoundation.palette.supporting.red[300];
      }
  }
};

export const getBorderColor = ({
  variant,
  color,
  isDisabled,
}: {
  variant: StyleProps['variant'];
  color: StyleProps['color'];
  isDisabled: StyleProps['isDisabled'];
}) => {
  if (isDisabled) {
    switch (variant) {
      case 'tertiary':
      case 'link':
        return 'transparent';
      case 'ghost':
      case 'primary':
      case 'secondary':
      default:
        return themeFoundation.palette.neutral[800];
    }
  }

  switch (color) {
    case 'brand':
      switch (variant) {
        case 'secondary':
          return themeFoundation.palette.neutral[800];
        case 'tertiary':
        case 'link':
          return 'transparent';
        case 'primary':
        case 'ghost':
        default:
          return themeFoundation.palette.neutral.dark;
      }
    case 'danger':
    default:
      switch (variant) {
        case 'primary':
        case 'secondary':
          return themeFoundation.palette.supporting.red[300];
        case 'tertiary':
        case 'link':
          return 'transparent';
        case 'ghost':
        default:
          return themeFoundation.palette.supporting.red[300];
      }
  }
};

export const getGap = (size: StyleProps['size']) => {
  switch (size) {
    case 'small':
      return rem(4);
    case 'medium':
    case 'large':
    default:
      return rem(8);
  }
};

export const getTextDecoration = (variant: StyleProps['variant']) => {
  switch (variant) {
    case 'link':
      return 'underline';
    case 'primary':
    case 'secondary':
    case 'tertiary':
    case 'ghost':
    default:
      return 'none';
  }
};
