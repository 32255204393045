import { defineMessages } from 'react-intl';

export default defineMessages({
  needHelp: {
    id: 'ds.footer.need.help',
    defaultMessage: 'Besoin d’aide ?',
  },
  contact: {
    id: 'ds.footer.contact',
    defaultMessage: 'Contacte-nous !',
  },
});
