import { FC } from 'react';
import Image from 'next/image';

import { getImageFromCloudImage } from '@/utils/cloudFrontLoader';

const SherpasLogo: FC = () => (
  <Image
    src="https://sherpas-prod.s3.eu-west-3.amazonaws.com/assets/logo-sherpas-mkt.webp"
    alt="Cours particuliers et soutien scolaire avec Les Sherpas"
    width={200}
    height={20}
    loading="eager"
    loader={getImageFromCloudImage}
  />
);

export default SherpasLogo;
