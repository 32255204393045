import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/BaseSvg';

const IconBell: FC<ComponentProps<typeof BaseSvg>> = ({ className = '' }) => (
  <BaseSvg className={className}>
    <path d="M10 2C6.68632 2 4.00003 4.68629 4.00003 8V11.5858L3.29292 12.2929C3.00692 12.5789 2.92137 13.009 3.07615 13.3827C3.23093 13.7564 3.59557 14 4.00003 14H16C16.4045 14 16.7691 13.7564 16.9239 13.3827C17.0787 13.009 16.9931 12.5789 16.7071 12.2929L16 11.5858V8C16 4.68629 13.3137 2 10 2Z" />
    <path d="M10 18C8.34315 18 7 16.6569 7 15H13C13 16.6569 11.6569 18 10 18Z" />
  </BaseSvg>
);

export default IconBell;
