import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import type { BaseButtonProps } from '@/designSystem/Button/BaseButton/BaseButton';
import {
  getBackgroundColor,
  getBorderColor,
  getBorderRadius,
  getGap,
  getIconAndTypographyColor,
  getPadding,
  getTextDecoration,
} from '@/designSystem/Button/BaseButton/styles.utils';

export type StyleProps = {
  size: BaseButtonProps['size'];
  variant: BaseButtonProps['variant'];
  color: BaseButtonProps['color'];
  isDisabled: BaseButtonProps['isDisabled'];
  isFullWidth: BaseButtonProps['isFullWidth'];
  withIcon: boolean;
  withText: boolean;
  cornerSide: BaseButtonProps['cornerSide'];
};

export default makeStyles<StyleProps>()(
  (
    _,
    {
      variant,
      size,
      color,
      isDisabled,
      isFullWidth,
      withIcon,
      withText,
      cornerSide,
    }
  ) => ({
    container: {
      cursor: isDisabled ? 'unset' : 'pointer',
      display: 'flex',
      padding: getPadding({ size, withIcon, withText }),
      justifyContent: isFullWidth ? 'center' : 'flex-start',
      alignItems: 'center',
      width: isFullWidth ? '100%' : 'max-content',
      borderWidth: rem(1),
      borderStyle: 'solid',
      borderRadius: getBorderRadius({ size, cornerSide }),
      borderColor: getBorderColor({ variant, color, isDisabled }),
      gap: getGap(size),
      color: getIconAndTypographyColor({ variant, color, isDisabled }),
      backgroundColor: getBackgroundColor({ variant, color, isDisabled }),
      textDecoration: getTextDecoration(variant),
      '&:hover': {
        textDecoration: !isDisabled && 'revert',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    label: {
      color: 'currentColor',
    },
  })
);
