import IconAdjustments from '@/designSystem/Icons/IconAdjustments';
import IconArrowDownward from '@/designSystem/Icons/IconArrowDownward';
import IconArrowLeft from '@/designSystem/Icons/IconArrowLeft';
import IconBadgeCheck from '@/designSystem/Icons/IconBadgeCheck';
import IconBarChart from '@/designSystem/Icons/IconBarChart';
import IconBell from '@/designSystem/Icons/IconBell';
import IconBookOpen from '@/designSystem/Icons/IconBookOpen';
import IconCalendar from '@/designSystem/Icons/IconCalendar';
import IconCalendarOutlined from '@/designSystem/Icons/IconCalendarOutlined';
import IconCancel from '@/designSystem/Icons/IconCancel';
import IconCertified from '@/designSystem/Icons/IconCertified';
import IconCertifiedOutlined from '@/designSystem/Icons/IconCertifiedOutlined';
import IconChartSquareBar from '@/designSystem/Icons/IconChartSquareBar';
import IconChat from '@/designSystem/Icons/IconChat';
import IconCheck from '@/designSystem/Icons/IconCheck';
import IconCheckCircle from '@/designSystem/Icons/IconCheckCircle';
import IconCheckCircleOutlined from '@/designSystem/Icons/IconCheckCircleOutlined';
import IconChevronDoubleUp from '@/designSystem/Icons/IconChevronDoubleUp';
import IconChevronDown from '@/designSystem/Icons/IconChevronDown';
import IconChevronLeft from '@/designSystem/Icons/IconChevronLeft';
import IconChevronRight from '@/designSystem/Icons/IconChevronRight';
import IconChevronUp from '@/designSystem/Icons/IconChevronUp';
import IconCircle from '@/designSystem/Icons/IconCircle';
import IconCircleDotted from '@/designSystem/Icons/IconCircleDotted';
import IconClock from '@/designSystem/Icons/IconClock';
import IconCollection from '@/designSystem/Icons/IconCollection';
import IconCopy from '@/designSystem/Icons/IconCopy';
import IconCreditCard from '@/designSystem/Icons/IconCreditCard';
import IconDanger from '@/designSystem/Icons/IconDanger';
import IconDatabase from '@/designSystem/Icons/IconDatabase';
import IconDesktopComputer from '@/designSystem/Icons/IconDesktopComputer';
import IconDocument from '@/designSystem/Icons/IconDocument';
import IconDotsHorizontal from '@/designSystem/Icons/IconDotsHorizontal';
import IconDownload from '@/designSystem/Icons/IconDownload';
import IconEmojiHappy from '@/designSystem/Icons/IconEmojiHappy';
import IconEuro from '@/designSystem/Icons/IconEuro';
import IconFacebook from '@/designSystem/Icons/IconFacebook';
import IconFlag from '@/designSystem/Icons/IconFlag';
import IconFolder from '@/designSystem/Icons/IconFolder';
import IconGlobe from '@/designSystem/Icons/IconGlobe';
import IconGoogle from '@/designSystem/Icons/IconGoogle';
import IconInfo from '@/designSystem/Icons/IconInfo';
import IconInstagram from '@/designSystem/Icons/IconInstagram';
import IconLevel from '@/designSystem/Icons/IconLevel';
import IconLightBulb from '@/designSystem/Icons/IconLightBulb';
import IconLightningBolt from '@/designSystem/Icons/IconLightningBolt';
import IconLink from '@/designSystem/Icons/IconLink';
import IconLive from '@/designSystem/Icons/IconLive';
import IconLocation from '@/designSystem/Icons/IconLocation';
import IconLockClosed from '@/designSystem/Icons/IconLockClosed';
import IconMail from '@/designSystem/Icons/IconMail';
import IconMarianne from '@/designSystem/Icons/IconMarianne';
import IconMasterCard from '@/designSystem/Icons/IconMasterCard';
import IconMessage from '@/designSystem/Icons/IconMessage';
import IconMinus from '@/designSystem/Icons/IconMinus';
import IconMinusCircle from '@/designSystem/Icons/IconMinusCircle';
import IconMovie from '@/designSystem/Icons/IconMovie';
import IconNewspaper from '@/designSystem/Icons/IconNewspaper';
import IconOnline from '@/designSystem/Icons/IconOnline';
import IconPanel from '@/designSystem/Icons/IconPanel';
import IconPaperAirplane from '@/designSystem/Icons/IconPaperAirplane';
import IconPaperClip from '@/designSystem/Icons/IconPaperClip';
import IconPencil from '@/designSystem/Icons/IconPencil';
import IconPhone from '@/designSystem/Icons/IconPhone';
import IconPlay from '@/designSystem/Icons/IconPlay';
import IconPlus from '@/designSystem/Icons/IconPlus';
import IconPlusCircle from '@/designSystem/Icons/IconPlusCircle';
import IconPresentationChartLine from '@/designSystem/Icons/IconPresentationChartLine';
import IconProfile from '@/designSystem/Icons/IconProfile';
import IconReceiptTax from '@/designSystem/Icons/IconReceiptTax';
import IconRefresh from '@/designSystem/Icons/IconRefresh';
import IconReply from '@/designSystem/Icons/IconReply';
import IconSetting from '@/designSystem/Icons/IconSetting';
import IconSherpaGreen from '@/designSystem/Icons/IconSherpaGreen';
import IconSherpaWhite from '@/designSystem/Icons/IconSherpaWhite';
import IconShieldCheck from '@/designSystem/Icons/IconShieldCheck';
import IconSparkles from '@/designSystem/Icons/IconSparkles';
import IconSpotify from '@/designSystem/Icons/IconSpotify';
import IconStar from '@/designSystem/Icons/IconStar';
import IconSun from '@/designSystem/Icons/IconSun';
import IconTrash from '@/designSystem/Icons/IconTrash';
import IconUser from '@/designSystem/Icons/IconUser';
import IconUserGroup from '@/designSystem/Icons/IconUserGroup';
import IconUsers from '@/designSystem/Icons/IconUsers';
import IconVideoCamera from '@/designSystem/Icons/IconVideoCamera';
import IconViewList from '@/designSystem/Icons/IconViewList';
import IconVisa from '@/designSystem/Icons/IconVisa';
import IconX from '@/designSystem/Icons/IconX';
import IconXCircle from '@/designSystem/Icons/IconXCircle';
import IconYoutube from '@/designSystem/Icons/IconYoutube';

//! Merci de conserver l'ordre alphabétique
const iconMapping = {
  adjustments: IconAdjustments,
  arrowDownward: IconArrowDownward,
  arrowLeft: IconArrowLeft,
  badgeCheck: IconBadgeCheck,
  barChart: IconBarChart,
  bell: IconBell,
  bookOpen: IconBookOpen,
  calendar: IconCalendar,
  calendarOutlined: IconCalendarOutlined,
  cancel: IconCancel,
  certified: IconCertified,
  certifiedOutlined: IconCertifiedOutlined,
  chartSquareBar: IconChartSquareBar,
  chat: IconChat,
  check: IconCheck,
  checkCircle: IconCheckCircle,
  checkCircleOutlined: IconCheckCircleOutlined,
  chevronDoubleUp: IconChevronDoubleUp,
  chevronDown: IconChevronDown,
  chevronLeft: IconChevronLeft,
  chevronRight: IconChevronRight,
  chevronUp: IconChevronUp,
  circle: IconCircle,
  circleDotted: IconCircleDotted,
  clock: IconClock,
  collection: IconCollection,
  copy: IconCopy,
  creditCard: IconCreditCard,
  danger: IconDanger,
  database: IconDatabase,
  desktopComputer: IconDesktopComputer,
  document: IconDocument,
  dotsHorizontal: IconDotsHorizontal,
  download: IconDownload,
  emojiHappy: IconEmojiHappy,
  euro: IconEuro,
  facebook: IconFacebook,
  flag: IconFlag,
  folder: IconFolder,
  globe: IconGlobe,
  google: IconGoogle,
  info: IconInfo,
  instagram: IconInstagram,
  level: IconLevel,
  lightBulb: IconLightBulb,
  lightningBolt: IconLightningBolt,
  link: IconLink,
  live: IconLive,
  location: IconLocation,
  lockClosed: IconLockClosed,
  marianne: IconMarianne,
  mastercard: IconMasterCard,
  mail: IconMail,
  message: IconMessage,
  minus: IconMinus,
  minusCircle: IconMinusCircle,
  movie: IconMovie,
  newspaper: IconNewspaper,
  online: IconOnline,
  panel: IconPanel,
  paperAirplane: IconPaperAirplane,
  paperClip: IconPaperClip,
  pencil: IconPencil,
  phone: IconPhone,
  play: IconPlay,
  plus: IconPlus,
  plusCircle: IconPlusCircle,
  presentationChartLine: IconPresentationChartLine,
  profile: IconProfile,
  receiptTax: IconReceiptTax,
  refresh: IconRefresh,
  reply: IconReply,
  setting: IconSetting,
  sherpaGreen: IconSherpaGreen,
  sherpaWhite: IconSherpaWhite,
  shieldCheck: IconShieldCheck,
  sparkles: IconSparkles,
  spotify: IconSpotify,
  star: IconStar,
  sun: IconSun,
  trash: IconTrash,
  user: IconUser,
  userGroup: IconUserGroup,
  users: IconUsers,
  videoCamera: IconVideoCamera,
  viewList: IconViewList,
  visa: IconVisa,
  x: IconX,
  xCircle: IconXCircle,
  youtube: IconYoutube,
} as const;

export default iconMapping;
