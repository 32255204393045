import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

type Props = {
  hasTeacher: boolean;
  shouldDisplayLoginForm: boolean;
};

export default makeStyles<Props>()(
  ({ palette, breakpoints }, { hasTeacher, shouldDisplayLoginForm }) => ({
    container: {
      display: 'flex',
      flexFlow: 'column',
      width: '100%',
      [breakpoints.up('sm')]: {
        minWidth: rem(400),
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: !shouldDisplayLoginForm && hasTeacher ? rem(4) : rem(32),
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: `0 ${rem(20)}`,
    },
    teacherModal: {
      marginBottom: rem(32),
    },
    arrowBack: {
      border: `1px solid ${palette.neutral[800]}`,
      padding: `${rem(12)} ${rem(16)}`,
      borderRadius: rem(8),
      color: palette.neutral[200],
    },
    error: {
      marginBottom: rem(8),
    },
  })
);
