import { UserType } from '@les-sherpas/sherpas-toolbox';
import server from 'config';

import useLoginRedirectionLocalStorage from '@/components/LocalStorage/useLoginRedirectionLocalStorage';

type Props = {
  provider: 'google' | 'facebook';
  shouldRedirect: boolean;
  userType: UserType;
  shouldRegister: boolean;
};

const useGafamConnectionV1 = ({
  provider,
  shouldRedirect,
  userType,
  shouldRegister: canRegister,
}: Props) => {
  const { saveItem, getItem, removeItem } = useLoginRedirectionLocalStorage();

  const saveRedirect = () => {
    if (window.location.href !== `${window.location.origin}/signup`) {
      saveItem({});
    }
  };

  const onClick = () => {
    saveRedirect();

    const redirectUrl = new URL(`${server}auth/${userType}/${provider}`);
    redirectUrl.searchParams.set('canRegister', canRegister.toString());

    if (shouldRedirect) {
      const redirectParam = getItem() ?? window.location.href;

      if (redirectParam !== `${window.location.origin}/signup`) {
        redirectUrl.searchParams.set('redirect', redirectParam);
      } else {
        redirectUrl.searchParams.set('redirect', window.location.origin);
      }
    }

    removeItem();
    window.location.assign(redirectUrl);
  };

  return {
    onClick,
    saveRedirect,
  };
};

export default useGafamConnectionV1;
