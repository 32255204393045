import { ComponentProps, FC } from 'react';

import BaseSvg from '@/designSystem/Icon/BaseSvg';

const IconCheckCircleOutlined: FC<ComponentProps<typeof BaseSvg>> = ({
  className = '',
}) => (
  <BaseSvg className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.33334C6.31811 3.33334 3.33334 6.31811 3.33334 10C3.33334 13.6819 6.31811 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 10C16.6667 6.31811 13.6819 3.33334 10 3.33334ZM1.66667 10C1.66667 5.39763 5.39763 1.66667 10 1.66667C14.6024 1.66667 18.3333 5.39763 18.3333 10C18.3333 14.6024 14.6024 18.3333 10 18.3333C5.39763 18.3333 1.66667 14.6024 1.66667 10ZM13.0893 7.74408C13.4147 8.06952 13.4147 8.59716 13.0893 8.92259L9.75593 12.2559C9.43049 12.5814 8.90285 12.5814 8.57742 12.2559L6.91075 10.5893C6.58531 10.2638 6.58531 9.73619 6.91075 9.41075C7.23619 9.08531 7.76382 9.08531 8.08926 9.41075L9.16667 10.4882L11.9108 7.74408C12.2362 7.41865 12.7638 7.41865 13.0893 7.74408Z"
    />
  </BaseSvg>
);

export default IconCheckCircleOutlined;
