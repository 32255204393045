import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import { LoginSignUpVariant } from '@/Organisms/LoginSignUpModal/LoginSignUp.type';
import Modal from '@/Organisms/Modal/Modal';

import messages from './messages';

import useStyles from './styles';

type Props = {
  variant: LoginSignUpVariant;
  onClick: () => void;
};

const LoginSignUpModalFooter: FC<Props> = ({ variant, onClick }) => {
  const { classes } = useStyles();

  return (
    <Modal.Footer>
      <div className={classes.footer}>
        <Typography
          className={classes.alreadyRegister}
          variant="bodyLg"
          component="p"
        >
          <FormattedMessage {...messages.footerMessage} values={{ variant }} />
        </Typography>
        <button type="button" className={classes.button} onClick={onClick}>
          <Typography
            variant="bodyLg"
            component="p"
            className={classes.connect}
          >
            <FormattedMessage {...messages.footerButton} values={{ variant }} />
          </Typography>
        </button>
      </div>
    </Modal.Footer>
  );
};

export default LoginSignUpModalFooter;
