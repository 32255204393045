import { FC, PropsWithChildren } from 'react';

const Youtube: FC<PropsWithChildren<unknown>> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5586 7.19337C21.4439 6.76942 21.2201 6.38291 20.9096 6.07235C20.599 5.76179 20.2125 5.538 19.7885 5.42328C18.2365 5 11.9899 5 11.9899 5C11.9899 5 5.74326 5.01283 4.19122 5.43611C3.76727 5.55083 3.38077 5.77461 3.0702 6.08518C2.75964 6.39574 2.53586 6.78224 2.42113 7.2062C1.95168 9.96395 1.76954 14.166 2.43396 16.8134C2.54868 17.2374 2.77247 17.6239 3.08303 17.9344C3.39359 18.245 3.7801 18.4688 4.20405 18.5835C5.75609 19.0068 12.0027 19.0068 12.0027 19.0068C12.0027 19.0068 18.2493 19.0068 19.8014 18.5835C20.2253 18.4688 20.6118 18.245 20.9224 17.9344C21.233 17.6239 21.4567 17.2374 21.5715 16.8134C22.0666 14.0518 22.2192 9.85236 21.5586 7.19337Z"
      fill="#FF0000"
    />
    <path
      d="M10.0017 15.005L15.1837 12.0035L10.0017 9.00208V15.005Z"
      fill="white"
    />
  </svg>
);

export default Youtube;
