import { FC, PropsWithChildren } from 'react';

import NextLinkWithNoCss from '@/components/NextLinkWithNoCss/NextLinkWithNoCss';
import SherpasMobileIcon from '@/designSystem/Sherpas/SherpasIcon';
import SherpasDesktopLogo from '@/designSystem/Sherpas/SherpasLogo';
import { useIsMobile, useResponsiveClasses } from '@/utils/useResponsive';

import useStyles from './styles';

type Props = {
  onClick?: () => void;
  href: string;
  isSidePanelOpen?: boolean;
};

const LinkSherpa: FC<PropsWithChildren<Props>> = ({
  onClick = () => {},
  href,
  isSidePanelOpen = false,
}) => {
  const { classes, cx } = useStyles();
  const { classes: responsiveClasses } = useResponsiveClasses();
  const isMobile = useIsMobile();

  const SherpasLogo = () =>
    isSidePanelOpen || !isMobile ? (
      <SherpasDesktopLogo />
    ) : (
      <SherpasMobileIcon />
    );

  return (
    <NextLinkWithNoCss
      href={href}
      onClick={onClick}
      className={cx(
        classes.link,
        isMobile ? responsiveClasses.mobile : responsiveClasses.notMobile
      )}
    >
      <SherpasLogo />
    </NextLinkWithNoCss>
  );
};

export default LinkSherpa;
