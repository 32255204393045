import { FC } from 'react';

import BaseButton, {
  BaseButtonProps,
} from '@/designSystem/Button/BaseButton/BaseButton';

export type ButtonProps = Pick<
  BaseButtonProps,
  | 'size'
  | 'label'
  | 'icon'
  | 'iconColor'
  | 'variant'
  | 'color'
  | 'isDisabled'
  | 'onClick'
  | 'type'
  | 'isFullWidth'
>;

const Button: FC<ButtonProps> = (props) => (
  <BaseButton {...props} cornerSide="all" />
);

export default Button;
