import { FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import messages from './messages';

import useStyles from './styles';

export type Option = {
  text: string;
  highlight?: boolean;
};

type Props = {
  options: Option[][];
  onClick: Function;
  Icon?: FC<PropsWithChildren<unknown>>;
};

const Options: FC<PropsWithChildren<Props>> = ({
  options,
  onClick,
  Icon,
  ...rest
}) => {
  const optionsLength = options?.length;
  const { classes } = useStyles({ length: optionsLength });
  return (
    <div className={classes.root} {...rest}>
      {optionsLength === 0 ? (
        <Typography className={classes.noOptionText}>
          <FormattedMessage {...messages.noOptions} />
        </Typography>
      ) : (
        options?.map((option, i) => (
          <button
            className={classes.button}
            type="button"
            key={`autocomplete_option-${i}`}
            onClick={() => onClick(option.map((o) => o.text).join(''))}
          >
            {Icon && (
              <div className={classes.icon}>
                <Icon />
              </div>
            )}
            <Typography component="div" className={classes.text}>
              {option?.map((part, index) => (
                <span
                  key={index}
                  style={{
                    color: part.highlight ? '#51c088' : '#39415f',
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </Typography>
          </button>
        ))
      )}
    </div>
  );
};

export default Options;
