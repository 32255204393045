import { defineMessages } from 'react-intl';

export default defineMessages({
  footerMessage: {
    id: 'ds.organisms.login.modal.footer.message',
    defaultMessage: `{variant, select, 
      login {Pas encore de compte ?}
      other {Déjà inscrit ?}
    }`,
  },
  footerButton: {
    id: 'ds.organisms.login.modal.footer.button',
    defaultMessage: `{variant, select,
      login {Inscrivez-vous}
      other {Connectez-vous}
    }`,
  },
});
